import { ZodError } from 'zod';
import { claimLineItemCreateSchema } from './schemas';
import {
  AutomationLineItem,
  ClaimLineItemCreate,
  ClaimType,
  DraftLineItemClaim,
  LineItemClaim,
  Variant,
} from './types';

const formatZodErrors = (schemaError: ZodError<ClaimLineItemCreate>): string =>
  Object.entries(schemaError.format())
    .filter(([key]) => key !== '_errors')
    .map(
      ([key, errors]) =>
        `${key}: ${
          '_errors' in errors ?
            // eslint-disable-next-line no-underscore-dangle -- _errors is a zod property
            errors._errors.join(', ')
          : JSON.stringify(errors)
        }`,
    )
    .join('; ');

const checkClaimLineItemCreate = (
  claimLineItem: Partial<ClaimLineItemCreate>,
): ClaimLineItemCreate => {
  const parsed = claimLineItemCreateSchema.safeParse(claimLineItem);

  if (!parsed.success) {
    throw new Error(
      `Invalid claim line item: ${formatZodErrors(parsed.error)}`,
    );
  }

  return parsed.data;
};

const extractVariantExchangeLineItem = (
  variant: Variant,
  quantity: number,
): ClaimLineItemCreate['variantExchangeLineItem'] => ({
  quantity,
  idFromPlatform: variant.idFromPlatform,
});

const extractCustomFields = (
  record: LineItemClaim['customFields'] = {},
): ClaimLineItemCreate['customFields'] =>
  Object.entries(record).map(([id, value]) => ({
    id: Number(id),
    value,
  }));

const extractClaimLineItemCreate = (
  claim: LineItemClaim,
): Partial<ClaimLineItemCreate> => ({
  originalStoreOrderLineItemId: claim.lineItem.id,
  quantity: claim.quantity,
  reason: claim.reason,
  unitPrice: claim.unitPrice,
  unitTax: claim.unitTax,
  requestedResolutionMethodEnum: claim.requestedResolutionMethodEnum,
  images: claim.assets?.map(({ name }) => name),
  comments: claim.comments ?? null,
  customFields: extractCustomFields(claim.customFields),
  ...(claim.variant && {
    variantExchangeLineItem: extractVariantExchangeLineItem(
      claim.variant,
      claim.quantity,
    ),
  }),
});

export const pendingClaimsToClaimLineItemCreate = (
  lineItemClaims: LineItemClaim[],
): ClaimLineItemCreate[] =>
  lineItemClaims.map((claim) =>
    checkClaimLineItemCreate(extractClaimLineItemCreate(claim)),
  );

export const getClaimReasons = (claim: DraftLineItemClaim) =>
  claim.claimType === ClaimType.return ?
    claim.lineItem.return.claimReasons
  : claim.lineItem.warranty.claimReasons;

export const pendingLineItemClaimToAutomationLineItem = (
  claim: LineItemClaim,
) =>
  ({
    claimType: claim.claimType,
    lineItemId: claim.lineItem.id,
    reasonId: claim.reason.id,
    reasonCategoryCode: claim.reason.category,
    reasonDetailId: claim.reason.detail?.id,
    lineItemTotal: (claim.unitPrice + claim.unitTax) * claim.quantity,
    requestedResolutionMethod: claim.requestedResolutionMethodEnum,
    customFields: Object.entries(claim.customFields).map(([key, value]) => ({
      id: Number(key),
      value,
    })),
  }) satisfies AutomationLineItem;
