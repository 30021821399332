import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { Link, Outlet, ScrollRestoration, useMatch } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Button from '~/components/button';
import LinkButton from '~/components/link-button';
import supportedBrowsers from '~/supportedBrowsers';
import { formatDate } from '~/utils/formatters';
import { useIsAtIndex } from '~/utils/routing';
import { useCrewOrderData } from './[:store]/order/[:idFromPlatform]/order-loader';
import { useAppShellData } from './app-shell-loader';
import { useProtectionOrderData } from './reorder/[:idFromPlatform]/order-overview-loader';

function Header() {
  const { store = '' } = useAppShellData();

  const isBrowserSupported = supportedBrowsers.test(navigator.userAgent);

  return (
    <header
      className={twMerge(
        'flex min-h-16 flex-col items-center justify-center bg-white shadow',
      )}
    >
      {!isBrowserSupported && (
        <div
          data-testid="browser-support-banner"
          className="w-full bg-red-800 px-4 py-2 text-center text-white"
        >
          <p>
            Your browser appears to be out of date, and some features may not
            work as expected. Please update to a newer version if you experience
            any difficulties.
          </p>
        </div>
      )}
      <Link
        to={`/${store}`}
        className="my-3 h-10 w-full max-w-md"
        aria-label="Go to Order look up"
      >
        <div
          className="h-full w-full bg-header-logo bg-contain bg-center bg-no-repeat"
          data-testid="header-logo"
        />
      </Link>
    </header>
  );
}

function AppBar() {
  const { store = '', isEmbedded } = useAppShellData();
  const index = useIsAtIndex();
  const inactivePage = useMatch('/:store/inactive');
  const isRegisterPage = useMatch({ path: '/:store/register/', end: false });
  /**
   * These two hooks are wrappers around the useRouteLoaderData hook that provide
   * the order data to the AppShell component. The AppShell loader query
   * the Stores for order data as it may not have the order set before the loader resolves.
   * Providing these hooks avoid querying the order data in the AppShell
   * when matching the order, reorder routes and should keep the AppShell component
   * in sync with the order data.
   */
  const orderData = useCrewOrderData();
  const protectionOrderData = useProtectionOrderData();

  if (index || inactivePage || isRegisterPage) {
    return null;
  }

  const {
    order: { orderNo, createdOn },
  } = orderData ?? protectionOrderData ?? { order: {} };

  const linkStyles = 'text-gray-800 hover:text-gray-600 p-1 text-sm';
  const iconStyles = 'size-4 mr-1';

  return (
    <nav className="-mx-4 -mt-8 mb-4 rounded-t-md p-4 text-gray-800">
      <div className="flex flex-col items-center justify-between gap-1 md:flex-row">
        <div className="flex w-full items-center justify-between gap-1 md:w-auto">
          {isEmbedded && (
            <Button
              variant="text"
              className={linkStyles}
              onClick={() => window.history.back()}
            >
              <ChevronLeftIcon className={twMerge(iconStyles, 'size-5')} /> Back
            </Button>
          )}

          <LinkButton variant="text" to={`/${store}`} className={linkStyles}>
            <MagnifyingGlassIcon className={iconStyles} />
            Search
          </LinkButton>
        </div>

        {orderNo && createdOn && (
          <div className="flex w-full justify-between gap-1 px-1 text-xs font-medium md:w-auto md:flex-col md:px-0 md:text-right">
            <span>Order: {orderNo}</span>
            <span>Order Date: {formatDate(createdOn)}</span>
          </div>
        )}
      </div>
    </nav>
  );
}

function Footer() {
  const { footerText } = useAppShellData();

  return (
    <footer
      className="mt-8 flex flex-col items-stretch justify-between space-x-4 md:flex-row"
      data-testid="app-footer"
    >
      <div className="flex items-center justify-center text-center md:justify-start md:text-left">
        {footerText ?
          <p className="text-xs">{footerText}</p>
        : <p className="text-xs">
            {' '}
            Need help? Reach out to{' '}
            <a
              className="text-primary-on-container hover:underline"
              href="mailto:support@corso.com"
            >
              support@corso.com
            </a>
            .
          </p>
        }
      </div>
      <div className="mt-8 flex items-center justify-center text-xs md:mt-0 md:justify-start">
        <span className="w-14">
          <a
            href="https://corso.com/returns-exchanges"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Corso Logo"
              src="https://corso-media.sfo3.cdn.digitaloceanspaces.com/img/corso-logo.png"
            />
          </a>
        </span>
      </div>
    </footer>
  );
}

function Body() {
  const { isEmbedded } = useAppShellData();

  return (
    <div
      className={twMerge(
        'h-auto bg-banner bg-cover bg-fixed text-base',
        !isEmbedded && 'md:bg-banner-img',
      )}
    >
      <div
        className={twMerge(
          'mx-auto h-auto max-w-3xl p-2',
          !isEmbedded && 'h-full p-0 md:pb-4 md:pt-8',
        )}
      >
        <div
          id="app-body"
          className={twMerge(
            'relative h-auto w-full rounded-none border border-gray-200 bg-gray-50 px-4 py-8 shadow md:block md:rounded-lg',
            !isEmbedded && 'flex h-full flex-col justify-between md:h-auto',
          )}
        >
          <AppBar />
          <main className="flex-1">
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default function AppShell() {
  const { isEmbedded } = useAppShellData();

  return isEmbedded ?
      <Body />
    : <div className="h-full">
        <div className="relative grid h-full grid-rows-[min-content_1fr]">
          <Header />
          <Body />
          <ScrollRestoration />
        </div>
      </div>;
}
