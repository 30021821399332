/* eslint-disable no-nested-ternary */
import { Form } from 'react-router-dom';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Field from '~/components/form-controls/field';
import FileUploader from '~/components/form-controls/file-uploader';
import Input from '~/components/form-controls/input';
import { Select, SelectItem } from '~/components/form-controls/select';
import TextArea from '~/components/form-controls/textarea';
import { useIsLoading } from '~/utils/routing';
import { FormKeys, useMetaActionResult } from './meta-action';
import { ClaimMetaData, useMetaData } from './meta-loader';

type FieldType = ClaimMetaData['customFields'][0]['valueType'];

const lookupInputType = (valueType: FieldType) => {
  switch (valueType) {
    case 'Number':
      return 'number';
    case 'Date':
      return 'date';
    default:
      return 'text';
  }
};

export default function ClaimMeta() {
  const isLoading = useIsLoading();

  const {
    claim,
    customFields,
    showDefaultCommentField,
    mediaUploadInstructions,
    requiredMediaCount,
    isDefaultCommentFieldRequired,
  } = useMetaData();

  const actionResult = useMetaActionResult();
  const hasErrors = actionResult && !actionResult.ok;
  const showAlert = hasErrors && actionResult.message;
  const showFieldHelpText = hasErrors && !actionResult.message;

  const customFieldsExist = customFields.length > 0;

  return (
    <Card
      headline={
        customFieldsExist ?
          `Let's get some more information`
        : 'Anything else you can tell us?'
      }
    >
      <Form
        className="flex flex-col gap-6"
        method="post"
        encType="multipart/form-data"
        name="claimMeta"
      >
        {customFields.map((field) =>
          field.valueType === 'Select' ?
            <Select
              key={field.id}
              required
              id={`${field.id}`}
              name={`${field.id}`}
              label={field.question}
            >
              {field.options.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </Select>
          : <Input
              key={field.id}
              id={`${field.id}`}
              name={`${field.id}`}
              label={field.question}
              type={lookupInputType(field.valueType)}
              required
              defaultValue={claim.customFields[field.id]}
            />,
        )}

        {showDefaultCommentField && (
          <TextArea
            id="comments"
            name={FormKeys.Comments}
            defaultValue={claim.comments ?? ''}
            required={Boolean(isDefaultCommentFieldRequired)}
            label="Additional comments"
          />
        )}

        <FileUploader
          name={FormKeys.Assets}
          value={claim.assets}
          requiredCount={requiredMediaCount}
          description={mediaUploadInstructions}
        />

        {showFieldHelpText && (
          <Field.HelpText error>{actionResult.form?.assets}</Field.HelpText>
        )}

        {showAlert && (
          <Alert variant="error" title={actionResult.message ?? ''}>
            <p>{actionResult.form?.assets}</p>
          </Alert>
        )}

        <input
          type="hidden"
          data-testid="required-asset-count"
          name={FormKeys.RequiredAssetCount}
          value={String(requiredMediaCount)}
        />

        <Submit loading={isLoading}>Next</Submit>
      </Form>
    </Card>
  );
}
