import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import { toConjunctiveList } from '~/utils/formatters';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { mediaAssetSchema, stringToJSONSchema } from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

export enum FormKeys {
  Comments = 'comments',
  Assets = 'assets',
  RequiredAssetCount = 'requiredAssetCount',
}

const schema = zfd.formData({
  [FormKeys.Comments]: zfd
    .text(z.string().optional())
    .transform((value) => value ?? null),
  [FormKeys.RequiredAssetCount]: zfd.numeric(z.number().int().nonnegative()),
  [FormKeys.Assets]: zfd.repeatableOfType(
    stringToJSONSchema.pipe(mediaAssetSchema),
  ),
  files: zfd
    .repeatable(z.array(z.instanceof(File)).optional())
    .transform((files) => files?.filter((file) => file.size > 0) ?? []),
});

/**
 * ! Caution: destructive operation on formData!
 * @description
 * Remove the keys that are not custom fields
 * and convert the FormData object to a plain object
 * with string values
 * */
const getCustomFields = (formData: FormData) => {
  formData.delete(FormKeys.Assets);
  formData.delete('files');
  formData.delete(FormKeys.Comments);
  formData.delete(FormKeys.RequiredAssetCount);

  return Object.entries(Object.fromEntries(formData)).reduce(
    (fields, [id, value]) => ({
      ...fields,
      [id]: String(value),
    }),
    {} satisfies Record<string, string>,
  );
};

export default crewAction(
  withDraftClaim(({ formData, claim, context }) => {
    const { storefrontId } = context.settings;
    const results = schema.safeParse(formData);

    if (!results.success) {
      return error(
        new Error('Malformed form data', {
          cause: {
            error: results.error,
            entries: Array.from(Object.entries(formData)),
          },
        }),
      );
    }

    const {
      [FormKeys.Comments]: comments,
      [FormKeys.Assets]: assets,
      files,
      [FormKeys.RequiredAssetCount]: requiredAssetCount,
    } = results.data;

    const existingMediaCount = assets.length + files.length;
    const assetsRequired = requiredAssetCount > 0;

    if (assetsRequired && existingMediaCount < requiredAssetCount) {
      return json<ActionResult<FormKeys>>({
        ok: false,
        form: {
          [FormKeys.Assets]: `A minimum of ${requiredAssetCount} media ${requiredAssetCount === 1 ? 'file is' : 'files are'} required.`,
        },
      });
    }

    const uploads =
      files.length ?
        api.uploadFiles({
          params: { storefrontId },
          query: { destination: 'crewClaimImg' },
          body: files,
        })
      : Promise.resolve({ assets: [], errors: [] });

    return uploads.then(({ assets: newAssets, errors }) => {
      context.setDraftClaim({
        ...claim,
        customFields: getCustomFields(formData),
        comments,
        assets: [...assets, ...newAssets],
      });

      return errors.length ?
          json<ActionResult<FormKeys>>({
            ok: false,
            message: 'Problem uploading files',
            form: {
              [FormKeys.Assets]: `${toConjunctiveList(
                errors,
              )} failed to upload.`,
            },
          })
        : redirect('../resolution', StatusCodes.SEE_OTHER);
    });
  }),
);

export const useMetaActionResult =
  createActionResultHook<ActionResult<FormKeys>>();
