import { z } from 'zod';
import { claimAddress } from './address.js';
import { registrationProductVariant } from './storeProduct.js';

export const registration = z.object({
  externalId: z.string(),
  createdOn: z.string(),
  estimatedPurchaseDate: z.string().nullish(),
  registrationChannelName: z.string(),
  proofOfPurchaseFileUrls: z.array(z.string()),
  orderIdFromPlatform: z.string(),
  email: z.string(),
  orderNumber: z.string().optional(),
});

export const registrationCreate = registration
  .omit({
    externalId: true,
    orderIdFromPlatform: true,
    createdOn: true,
    registrationChannelName: true,
    proofOfPurchaseFileUrls: true,
    estimatedPurchaseDate: true,
  })
  .merge(
    z.object({
      firstName: z.string(),
      lastName: z.string(),
      storeProductVariants: z.array(
        registrationProductVariant
          .pick({
            id: true,
          })
          .merge(z.object({ quantity: z.number() })),
      ),
      storeSalesChannelId: z.number(),
      address: claimAddress.optional(),
      estimatedPurchaseDate: z.string(),
      proofOfPurchaseFilenames: z.array(z.string()).optional(),
    }),
  );
