// ! UTILITY ENUMS TO ASSIST WITH DB

// * below this line are all ENUMs utilized by CREW *
export enum CrewClaimTypeEnum {
  warranty = 'Warranty',
  return = 'Return',
}

export enum CrewMetricType {
  totalOrders = 'Total_Orders', // ? total orders in date range
  ordersWithReturns = 'Orders_With_Returns', // ? total orders with returns in date range (order can have more than one return)
  totalReturnClaims = 'Total_Return_Claims', // ? total return claims in date range

  totalOrderRevenue = 'Total_Order_Revenue', // ? total revenue in date range
  totalReturnedRevenue = 'Total_Returned_Revenue', // ? total revenue from returned items in date range
  totalRefundedRevenue = 'Total_Refunded_Revenue', // ? total revenue / total returned revenue

  totalOutstandingReturnRevenue = 'Total_Outstanding_Return_Revenue', // ? total revenue from returns that are still open
  totalSavedRevenue = 'Total_Saved_Revenue', // ? total revenue that wasn't refunded (store credit or exchange)
  refundedRevenueRate = 'Refunded_Revenue_Rate', // ? total refunded revenue / total order revenue
}

// added for display purposes just in case the enum values are ever extended and contain a non-merchant-friendly value
export const crewClaimTypeEnumName = {
  [CrewClaimTypeEnum.warranty]: 'Warranty',
  [CrewClaimTypeEnum.return]: 'Return',
} as const satisfies Record<CrewClaimTypeEnum, string>;

export const crewClaimTypeEnumPluralizedName = {
  [CrewClaimTypeEnum.return]: 'Returns',
  [CrewClaimTypeEnum.warranty]: 'Warranties',
} as const satisfies Record<CrewClaimTypeEnum, string>;

export enum CrewIncentiveTypeEnum {
  percent = 'Percent',
  fixed = 'Fixed',
  none = 'None',
}

export enum CrewShippingDirectionEnum {
  toCustomer = 'To_Customer',
  fromCustomer = 'From_Customer',
}

// ------------------ CREW INSPECTION Classifications ------------------ //

export enum InspectionGradeCode {
  aStock = 'A_Stock',
  bStock = 'B_Stock',
  dispose = 'Dispose',
}

export const inspectionGradeCodeNames = {
  [InspectionGradeCode.aStock]: 'A-Stock',
  [InspectionGradeCode.bStock]: 'B-Stock',
  [InspectionGradeCode.dispose]: 'Dispose',
} as const satisfies Record<InspectionGradeCode, string>;

//* claim resolution method enum is used by GSP and CREW *//

export enum CrewClaimResolutionMethodEnum {
  refund = 'Refund',
  variantExchange = 'Variant_Exchange', // ? one for one of the same product
  giftCard = 'Gift_Card',
  repair = 'Repair',
  replacementOrder = 'Replacement_Order', // ? any number of products can be replaced
  warrantyReview = 'Warranty_Review',
}

// ! todo consolidate this once we have repairs exposed
export enum SearchableCrewClaimResolutionMethod {
  refund = 'Refund',
  variantExchange = 'Variant_Exchange', // ? one for one of the same product
  giftCard = 'Gift_Card',
  warrantyReview = 'Warranty_Review',
}

export const crewClaimResolutionMethodEnumName = {
  [CrewClaimResolutionMethodEnum.refund]: 'Refund',
  [CrewClaimResolutionMethodEnum.variantExchange]: 'Exchange',
  [CrewClaimResolutionMethodEnum.giftCard]: 'Gift Card',
  [CrewClaimResolutionMethodEnum.repair]: 'Repair',
  [CrewClaimResolutionMethodEnum.replacementOrder]: 'Replacement Order',
  [CrewClaimResolutionMethodEnum.warrantyReview]: 'Warranty Review',
} as const satisfies Record<CrewClaimResolutionMethodEnum, string>;

// ------------------ CREW CLAIM REASON CATEGORY CODES ------------------ //

export enum CrewClaimReasonCategoryCode {
  defectiveItem = 'Defective_Item',
  wrongFit = 'Wrong_Fit',
  notAsExpected = 'Not_As_Expected',
  notSatisfied = 'Not_Satisfied',
  noLongerNeeded = 'No_Longer_Needed',
  wrongItem = 'Wrong_Item',
  shippingIssue = 'Shipping_Issue',
}

export const crewClaimReasonCategoryCodeName = {
  [CrewClaimReasonCategoryCode.defectiveItem]: 'Defective Item',
  [CrewClaimReasonCategoryCode.wrongFit]: 'Wrong Fit',
  [CrewClaimReasonCategoryCode.notAsExpected]: 'Not As Expected',
  [CrewClaimReasonCategoryCode.notSatisfied]: 'Not Satisfied',
  [CrewClaimReasonCategoryCode.noLongerNeeded]: 'No Longer Needed',
  [CrewClaimReasonCategoryCode.wrongItem]: 'Wrong Item',
  [CrewClaimReasonCategoryCode.shippingIssue]: 'Shipping Issue',
} as const satisfies Record<CrewClaimReasonCategoryCode, string>;

// ------------------ CREW CLAIM CUSTOMER EMAIL TYPES ------------------ //

export enum AutomatedEmailTypeEnum {
  // ? automated emails
  returnExpiring = 'Return_Expiring', // ? applies only to returns
  returnReminder = 'Return_Reminder', // ? applies only to returns
  claimSubmitted = 'Claim_Submitted', // ? applies to both returns
  registrationSubmitted = 'Registration_Submitted',
  warrantySubmitted = 'Warranty_Submitted', // ? applies only to warranties
}

export enum FinalizationEmailTypeEnum {
  // ? finalization / processing emails
  claimApprovedAsRequested = 'Claim_Approved_As_Requested', // return
  claimApprovedNotAsRequested = 'Claim_Approved_Not_As_Requested', // return
  claimDenied = 'Claim_Denied',

  claimNeedsMoreInfo = 'Claim_Needs_More_Info', // return + warranty

  warrantyApprovedAsRequested = 'Warranty_Approved_As_Requested', // warranty
  warrantyApprovedNotAsRequested = 'Warranty_Approved_Not_As_Requested', // warranty
  warrantyDenied = 'Warranty_Denied',
}

// ------------------ CREW CLAIM RETURN SHIPMENT TYPES ------------------ //

export enum ShipmentMethod {
  packingSlip = 'Packing_Slip',
  label = 'Label',
}

// ------------------ CREW CLAIM STATUS CODES ------------------ //

export enum CrewClaimRollupStatusCode {
  inProgress = 'In_Progress',
  readyForReview = 'Ready_For_Review',
  completed = 'Completed',
}

export const crewClaimRollupStatusCodeName = {
  [CrewClaimRollupStatusCode.inProgress]: 'In Progress',
  [CrewClaimRollupStatusCode.readyForReview]: 'Ready For Review',
  [CrewClaimRollupStatusCode.completed]: 'Completed',
} as const satisfies Record<CrewClaimRollupStatusCode, string>;

// ------------------ CREW CLAIM LINE ITEM STATUS CODES ------------------ //

export enum CrewClaimStatusCode {
  open = 'Open',
  awaitingReturn = 'Awaiting_Return',
  inTransit = 'In_Transit',
  atReturnLocation = 'At_Return_Location',
  approved = 'Approved',
  denied = 'Denied',
  closed = 'Closed',
}

export enum CrewClaimStatusDetailCode {
  finalSale = 'Final_Sale',
  pendingReview = 'Pending_Review',
  pendingAdditionalInfo = 'Pending_Additional_Info',
  awaitingCustomerDropoffLabelProvided = 'Awaiting_Customer_Dropoff_Label_Provided',
  awaitingCustomerDropOffPackingSlipProvided = 'Awaiting_Customer_Dropoff_Packing_Slip_Provided',
  inTransit = 'In_Transit',
  pendingInspection = 'Pending_Inspection',
  pendingApproval = 'Pending_Approval',
  variantExchange = 'Variant_Exchange',
  giftCardIssued = 'Gift_Card_Issued',
  refunded = 'Refunded',
  refundedPartial = 'Refunded_Partial',
  gifCardIssuedPartial = 'Gift_Card_Issued_Partial',
  replacementOrder = 'Replacement_Order',
  repaired = 'Repaired',
  wrongItem = 'Wrong_Item',
  itemNotReturned = 'Item_Not_Returned',
  noncompliantReturn = 'Noncompliant_Return',
  notCoveredUnderWarranty = 'Not_Covered_Under_Warranty',
  pendingReturnShipmentCreation = 'Pending_Return_Shipment_Creation',
  returnNotRequired = 'Return_Not_Required',
  additionalInfoNotProvided = 'Additional_Info_Not_Provided',
  noActionTaken = 'No_Action_Taken',
}

// ------------------ CREW CLAIM LINE ITEM STATUS HELPERS ------------------ //

type CrewClaimStatusDetailHelper = {
  readonly code: CrewClaimStatusDetailCode;
  readonly name: string;
};

export type ClaimStatusHelper = {
  readonly code: CrewClaimStatusCode;
  readonly name: string;
  readonly claimStatusDetail: Readonly<CrewClaimStatusDetailHelper[]>;
};

const claimStatusOpen = {
  code: CrewClaimStatusCode.open,
  name: 'Open',
  claimStatusDetail: [
    { code: CrewClaimStatusDetailCode.pendingReview, name: 'Pending Review' },
    {
      code: CrewClaimStatusDetailCode.pendingAdditionalInfo,
      name: 'Pending Additional Info',
    },
    {
      code: CrewClaimStatusDetailCode.pendingReturnShipmentCreation,
      name: 'Pending Return Shipment Creation',
    },
    {
      code: CrewClaimStatusDetailCode.returnNotRequired,
      name: 'Return Not Required',
    },
  ] as const,
} satisfies ClaimStatusHelper;

const claimStatusAwaitingReturn = {
  code: CrewClaimStatusCode.awaitingReturn,
  name: 'Awaiting Return',
  claimStatusDetail: [
    {
      code: CrewClaimStatusDetailCode.awaitingCustomerDropoffLabelProvided,
      name: 'Awaiting Customer Dropoff',
    },
    {
      code: CrewClaimStatusDetailCode.awaitingCustomerDropOffPackingSlipProvided,
      name: 'Awaiting Customer Dropoff',
    },
  ] as const,
} satisfies ClaimStatusHelper;

const claimStatusInTransit = {
  code: CrewClaimStatusCode.inTransit,
  name: 'In Transit',
  claimStatusDetail: [
    { code: CrewClaimStatusDetailCode.inTransit, name: 'In Transit' },
  ] as const,
} satisfies ClaimStatusHelper;

const claimStatusAtReturnLocation = {
  code: CrewClaimStatusCode.atReturnLocation,
  name: 'At Return Location',
  claimStatusDetail: [
    {
      code: CrewClaimStatusDetailCode.pendingInspection,
      name: 'Pending Inspection',
    },
    {
      code: CrewClaimStatusDetailCode.pendingApproval,
      name: 'Pending Approval',
    },
    {
      code: CrewClaimStatusDetailCode.pendingAdditionalInfo,
      name: 'Pending Additional Info',
    },
  ] as const,
} satisfies ClaimStatusHelper;

const claimStatusApproved = {
  code: CrewClaimStatusCode.approved,
  name: 'Approved',
  claimStatusDetail: [
    {
      code: CrewClaimStatusDetailCode.variantExchange,
      name: 'Variant Exchange',
    },
    {
      code: CrewClaimStatusDetailCode.giftCardIssued,
      name: 'Gift Card Issued',
    },
    {
      code: CrewClaimStatusDetailCode.refunded,
      name: 'Refunded',
    },
    {
      code: CrewClaimStatusDetailCode.refundedPartial,
      name: 'Partially Refunded',
    },
    {
      code: CrewClaimStatusDetailCode.gifCardIssuedPartial,
      name: 'Partial Gift Card Issued',
    },
    {
      code: CrewClaimStatusDetailCode.replacementOrder,
      name: 'Replacement Order',
    },
    {
      code: CrewClaimStatusDetailCode.repaired,
      name: 'Repaired',
    },
  ] as const,
} satisfies ClaimStatusHelper;

const claimStatusClosed = {
  code: CrewClaimStatusCode.closed,
  name: 'Closed',
  claimStatusDetail: [
    {
      code: CrewClaimStatusDetailCode.noActionTaken,
      name: 'Nothing Issued',
    },
  ] as const,
} satisfies ClaimStatusHelper;

// -------- DENIED CLAIM STATUS DETAILS -------- //
const claimStatusDenied = {
  code: CrewClaimStatusCode.denied,
  name: 'Denied',
  claimStatusDetail: [
    {
      code: CrewClaimStatusDetailCode.wrongItem,
      name: 'Wrong Item',
    },
    {
      code: CrewClaimStatusDetailCode.itemNotReturned,
      name: 'Item Not Returned',
    },
    {
      code: CrewClaimStatusDetailCode.noncompliantReturn,
      name: 'Non-compliant Return',
    },
    {
      code: CrewClaimStatusDetailCode.notCoveredUnderWarranty,
      name: 'Not Covered Under Warranty',
    },
    {
      code: CrewClaimStatusDetailCode.noActionTaken,
      name: 'Nothing Issued Inside Corso',
    },
    // ? additional info not provided was removed
  ] as const,
} satisfies ClaimStatusHelper;

type ClaimEnforcer = {
  [Key in CrewClaimStatusCode]: ClaimStatusHelper;
};

/**
 * Helper object to assist with seeding and know which `claim_status_detail` records apply to which `claim_status`.
 * Should be instead of the underlying `claim_status_detail` related enum when working with related statuses and details.
 */
// ? we may want to consider reviewing this format for object-based access of details instead of index-based access
export const crewClaimStatuses = {
  [CrewClaimStatusCode.open]: claimStatusOpen,
  [CrewClaimStatusCode.awaitingReturn]: claimStatusAwaitingReturn,
  [CrewClaimStatusCode.inTransit]: claimStatusInTransit,
  [CrewClaimStatusCode.atReturnLocation]: claimStatusAtReturnLocation,
  [CrewClaimStatusCode.approved]: claimStatusApproved,
  [CrewClaimStatusCode.denied]: claimStatusDenied,
  [CrewClaimStatusCode.closed]: claimStatusClosed,
} satisfies ClaimEnforcer;

// ------------------ COUNTRY CODE ------------------ //

export const usaStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'DC',
] as const;

export enum GeoCountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  AN = 'AN',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  CS = 'CS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UM = 'UM',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}

type CountryCode = Readonly<{
  code: GeoCountryCode;
  name: string;
  provinces: readonly string[];
}>;

export const geoCountryCodes = {
  [GeoCountryCode.AF]: {
    code: GeoCountryCode.AF,
    name: 'Afghanistan',
    provinces: [],
  },
  [GeoCountryCode.AX]: {
    code: GeoCountryCode.AX,
    name: 'Åland Islands',
    provinces: [],
  },
  [GeoCountryCode.AL]: {
    code: GeoCountryCode.AL,
    name: 'Albania',
    provinces: [],
  },
  [GeoCountryCode.DZ]: {
    code: GeoCountryCode.DZ,
    name: 'Algeria',
    provinces: [],
  },
  [GeoCountryCode.AS]: {
    code: GeoCountryCode.AS,
    name: 'American Samoa',
    provinces: [],
  },
  [GeoCountryCode.AD]: {
    code: GeoCountryCode.AD,
    name: 'AndorrA',
    provinces: [],
  },
  [GeoCountryCode.AO]: {
    code: GeoCountryCode.AO,
    name: 'Angola',
    provinces: [],
  },
  [GeoCountryCode.AI]: {
    code: GeoCountryCode.AI,
    name: 'Anguilla',
    provinces: [],
  },
  [GeoCountryCode.AQ]: {
    code: GeoCountryCode.AQ,
    name: 'Antarctica',
    provinces: [],
  },
  [GeoCountryCode.AG]: {
    code: GeoCountryCode.AG,
    name: 'Antigua and Barbuda',
    provinces: [],
  },
  [GeoCountryCode.AR]: {
    code: GeoCountryCode.AR,
    name: 'Argentina',
    provinces: [],
  },
  [GeoCountryCode.AM]: {
    code: GeoCountryCode.AM,
    name: 'Armenia',
    provinces: [],
  },
  [GeoCountryCode.AW]: {
    code: GeoCountryCode.AW,
    name: 'Aruba',
    provinces: [],
  },
  [GeoCountryCode.AU]: {
    code: GeoCountryCode.AU,
    name: 'Australia',
    provinces: [],
  },
  [GeoCountryCode.AT]: {
    code: GeoCountryCode.AT,
    name: 'Austria',
    provinces: [],
  },
  [GeoCountryCode.AZ]: {
    code: GeoCountryCode.AZ,
    name: 'Azerbaijan',
    provinces: [],
  },
  [GeoCountryCode.BS]: {
    code: GeoCountryCode.BS,
    name: 'Bahamas',
    provinces: [],
  },
  [GeoCountryCode.BH]: {
    code: GeoCountryCode.BH,
    name: 'Bahrain',
    provinces: [],
  },
  [GeoCountryCode.BD]: {
    code: GeoCountryCode.BD,
    name: 'Bangladesh',
    provinces: [],
  },
  [GeoCountryCode.BB]: {
    code: GeoCountryCode.BB,
    name: 'Barbados',
    provinces: [],
  },
  [GeoCountryCode.BY]: {
    code: GeoCountryCode.BY,
    name: 'Belarus',
    provinces: [],
  },
  [GeoCountryCode.BE]: {
    code: GeoCountryCode.BE,
    name: 'Belgium',
    provinces: [],
  },
  [GeoCountryCode.BZ]: {
    code: GeoCountryCode.BZ,
    name: 'Belize',
    provinces: [],
  },
  [GeoCountryCode.BJ]: {
    code: GeoCountryCode.BJ,
    name: 'Benin',
    provinces: [],
  },
  [GeoCountryCode.BM]: {
    code: GeoCountryCode.BM,
    name: 'Bermuda',
    provinces: [],
  },
  [GeoCountryCode.BT]: {
    code: GeoCountryCode.BT,
    name: 'Bhutan',
    provinces: [],
  },
  [GeoCountryCode.BO]: {
    code: GeoCountryCode.BO,
    name: 'Bolivia',
    provinces: [],
  },
  [GeoCountryCode.BA]: {
    code: GeoCountryCode.BA,
    name: 'Bosnia and Herzegovina',
    provinces: [],
  },
  [GeoCountryCode.BW]: {
    code: GeoCountryCode.BW,
    name: 'Botswana',
    provinces: [],
  },
  [GeoCountryCode.BV]: {
    code: GeoCountryCode.BV,
    name: 'Bouvet Island',
    provinces: [],
  },
  [GeoCountryCode.BR]: {
    code: GeoCountryCode.BR,
    name: 'Brazil',
    provinces: [],
  },
  [GeoCountryCode.IO]: {
    code: GeoCountryCode.IO,
    name: 'British Indian Ocean Territory',
    provinces: [],
  },
  [GeoCountryCode.BN]: {
    code: GeoCountryCode.BN,
    name: 'Brunei Darussalam',
    provinces: [],
  },
  [GeoCountryCode.BG]: {
    code: GeoCountryCode.BG,
    name: 'Bulgaria',
    provinces: [],
  },
  [GeoCountryCode.BF]: {
    code: GeoCountryCode.BF,
    name: 'Burkina Faso',
    provinces: [],
  },
  [GeoCountryCode.BI]: {
    code: GeoCountryCode.BI,
    name: 'Burundi',
    provinces: [],
  },
  [GeoCountryCode.KH]: {
    code: GeoCountryCode.KH,
    name: 'Cambodia',
    provinces: [],
  },
  [GeoCountryCode.CM]: {
    code: GeoCountryCode.CM,
    name: 'Cameroon',
    provinces: [],
  },
  [GeoCountryCode.CA]: {
    code: GeoCountryCode.CA,
    name: 'Canada',
    provinces: [],
  },
  [GeoCountryCode.CV]: {
    code: GeoCountryCode.CV,
    name: 'Cape Verde',
    provinces: [],
  },
  [GeoCountryCode.KY]: {
    code: GeoCountryCode.KY,
    name: 'Cayman Islands',
    provinces: [],
  },
  [GeoCountryCode.CF]: {
    code: GeoCountryCode.CF,
    name: 'Central African Republic',
    provinces: [],
  },
  [GeoCountryCode.TD]: { code: GeoCountryCode.TD, name: 'Chad', provinces: [] },
  [GeoCountryCode.CL]: {
    code: GeoCountryCode.CL,
    name: 'Chile',
    provinces: [],
  },
  [GeoCountryCode.CN]: {
    code: GeoCountryCode.CN,
    name: 'China',
    provinces: [],
  },
  [GeoCountryCode.CX]: {
    code: GeoCountryCode.CX,
    name: 'Christmas Island',
    provinces: [],
  },
  [GeoCountryCode.CC]: {
    code: GeoCountryCode.CC,
    name: 'Cocos (Keeling) Islands',
    provinces: [],
  },
  [GeoCountryCode.CO]: {
    code: GeoCountryCode.CO,
    name: 'Colombia',
    provinces: [],
  },
  [GeoCountryCode.KM]: {
    code: GeoCountryCode.KM,
    name: 'Comoros',
    provinces: [],
  },
  [GeoCountryCode.CG]: {
    code: GeoCountryCode.CG,
    name: 'Congo',
    provinces: [],
  },
  [GeoCountryCode.CD]: {
    code: GeoCountryCode.CD,
    name: 'Congo, The Democratic Republic of the',
    provinces: [],
  },
  [GeoCountryCode.CK]: {
    code: GeoCountryCode.CK,
    name: 'Cook Islands',
    provinces: [],
  },
  [GeoCountryCode.CR]: {
    code: GeoCountryCode.CR,
    name: 'Costa Rica',
    provinces: [],
  },
  [GeoCountryCode.CI]: {
    code: GeoCountryCode.CI,
    name: 'Cote D"Ivoire',
    provinces: [],
  },
  [GeoCountryCode.HR]: {
    code: GeoCountryCode.HR,
    name: 'Croatia',
    provinces: [],
  },
  [GeoCountryCode.CU]: { code: GeoCountryCode.CU, name: 'Cuba', provinces: [] },
  [GeoCountryCode.CY]: {
    code: GeoCountryCode.CY,
    name: 'Cyprus',
    provinces: [],
  },
  [GeoCountryCode.CZ]: {
    code: GeoCountryCode.CZ,
    name: 'Czech Republic',
    provinces: [],
  },
  [GeoCountryCode.DK]: {
    code: GeoCountryCode.DK,
    name: 'Denmark',
    provinces: [],
  },
  [GeoCountryCode.DJ]: {
    code: GeoCountryCode.DJ,
    name: 'Djibouti',
    provinces: [],
  },
  [GeoCountryCode.DM]: {
    code: GeoCountryCode.DM,
    name: 'Dominica',
    provinces: [],
  },
  [GeoCountryCode.DO]: {
    code: GeoCountryCode.DO,
    name: 'Dominican Republic',
    provinces: [],
  },
  [GeoCountryCode.EC]: {
    code: GeoCountryCode.EC,
    name: 'Ecuador',
    provinces: [],
  },
  [GeoCountryCode.EG]: {
    code: GeoCountryCode.EG,
    name: 'Egypt',
    provinces: [],
  },
  [GeoCountryCode.SV]: {
    code: GeoCountryCode.SV,
    name: 'El Salvador',
    provinces: [],
  },
  [GeoCountryCode.GQ]: {
    code: GeoCountryCode.GQ,
    name: 'Equatorial Guinea',
    provinces: [],
  },
  [GeoCountryCode.ER]: {
    code: GeoCountryCode.ER,
    name: 'Eritrea',
    provinces: [],
  },
  [GeoCountryCode.EE]: {
    code: GeoCountryCode.EE,
    name: 'Estonia',
    provinces: [],
  },
  [GeoCountryCode.ET]: {
    code: GeoCountryCode.ET,
    name: 'Ethiopia',
    provinces: [],
  },
  [GeoCountryCode.FK]: {
    code: GeoCountryCode.FK,
    name: 'Falkland Islands (Malvinas)',
    provinces: [],
  },
  [GeoCountryCode.FO]: {
    code: GeoCountryCode.FO,
    name: 'Faroe Islands',
    provinces: [],
  },
  [GeoCountryCode.FJ]: { code: GeoCountryCode.FJ, name: 'Fiji', provinces: [] },
  [GeoCountryCode.FI]: {
    code: GeoCountryCode.FI,
    name: 'Finland',
    provinces: [],
  },
  [GeoCountryCode.FR]: {
    code: GeoCountryCode.FR,
    name: 'France',
    provinces: [],
  },
  [GeoCountryCode.GF]: {
    code: GeoCountryCode.GF,
    name: 'French Guiana',
    provinces: [],
  },
  [GeoCountryCode.PF]: {
    code: GeoCountryCode.PF,
    name: 'French Polynesia',
    provinces: [],
  },
  [GeoCountryCode.TF]: {
    code: GeoCountryCode.TF,
    name: 'French Southern Territories',
    provinces: [],
  },
  [GeoCountryCode.GA]: {
    code: GeoCountryCode.GA,
    name: 'Gabon',
    provinces: [],
  },
  [GeoCountryCode.GM]: {
    code: GeoCountryCode.GM,
    name: 'Gambia',
    provinces: [],
  },
  [GeoCountryCode.GE]: {
    code: GeoCountryCode.GE,
    name: 'Georgia',
    provinces: [],
  },
  [GeoCountryCode.DE]: {
    code: GeoCountryCode.DE,
    name: 'Germany',
    provinces: [],
  },
  [GeoCountryCode.GH]: {
    code: GeoCountryCode.GH,
    name: 'Ghana',
    provinces: [],
  },
  [GeoCountryCode.GI]: {
    code: GeoCountryCode.GI,
    name: 'Gibraltar',
    provinces: [],
  },
  [GeoCountryCode.GR]: {
    code: GeoCountryCode.GR,
    name: 'Greece',
    provinces: [],
  },
  [GeoCountryCode.GL]: {
    code: GeoCountryCode.GL,
    name: 'Greenland',
    provinces: [],
  },
  [GeoCountryCode.GD]: {
    code: GeoCountryCode.GD,
    name: 'Grenada',
    provinces: [],
  },
  [GeoCountryCode.GP]: {
    code: GeoCountryCode.GP,
    name: 'Guadeloupe',
    provinces: [],
  },
  [GeoCountryCode.GU]: { code: GeoCountryCode.GU, name: 'Guam', provinces: [] },
  [GeoCountryCode.GT]: {
    code: GeoCountryCode.GT,
    name: 'Guatemala',
    provinces: [],
  },
  [GeoCountryCode.GG]: {
    code: GeoCountryCode.GG,
    name: 'Guernsey',
    provinces: [],
  },
  [GeoCountryCode.GN]: {
    code: GeoCountryCode.GN,
    name: 'Guinea',
    provinces: [],
  },
  [GeoCountryCode.GW]: {
    code: GeoCountryCode.GW,
    name: 'Guinea-Bissau',
    provinces: [],
  },
  [GeoCountryCode.GY]: {
    code: GeoCountryCode.GY,
    name: 'Guyana',
    provinces: [],
  },
  [GeoCountryCode.HT]: {
    code: GeoCountryCode.HT,
    name: 'Haiti',
    provinces: [],
  },
  [GeoCountryCode.HM]: {
    code: GeoCountryCode.HM,
    name: 'Heard Island and Mcdonald Islands',
    provinces: [],
  },
  [GeoCountryCode.VA]: {
    code: GeoCountryCode.VA,
    name: 'Holy See (Vatican City State)',
    provinces: [],
  },
  [GeoCountryCode.HN]: {
    code: GeoCountryCode.HN,
    name: 'Honduras',
    provinces: [],
  },
  [GeoCountryCode.HK]: {
    code: GeoCountryCode.HK,
    name: 'Hong Kong',
    provinces: [],
  },
  [GeoCountryCode.HU]: {
    code: GeoCountryCode.HU,
    name: 'Hungary',
    provinces: [],
  },
  [GeoCountryCode.IS]: {
    code: GeoCountryCode.IS,
    name: 'Iceland',
    provinces: [],
  },
  [GeoCountryCode.IN]: {
    code: GeoCountryCode.IN,
    name: 'India',
    provinces: [],
  },
  [GeoCountryCode.ID]: {
    code: GeoCountryCode.ID,
    name: 'Indonesia',
    provinces: [],
  },
  [GeoCountryCode.IR]: {
    code: GeoCountryCode.IR,
    name: 'Iran, Islamic Republic Of',
    provinces: [],
  },
  [GeoCountryCode.IQ]: { code: GeoCountryCode.IQ, name: 'Iraq', provinces: [] },
  [GeoCountryCode.IE]: {
    code: GeoCountryCode.IE,
    name: 'Ireland',
    provinces: [],
  },
  [GeoCountryCode.IM]: {
    code: GeoCountryCode.IM,
    name: 'Isle of Man',
    provinces: [],
  },
  [GeoCountryCode.IL]: {
    code: GeoCountryCode.IL,
    name: 'Israel',
    provinces: [],
  },
  [GeoCountryCode.IT]: {
    code: GeoCountryCode.IT,
    name: 'Italy',
    provinces: [],
  },
  [GeoCountryCode.JM]: {
    code: GeoCountryCode.JM,
    name: 'Jamaica',
    provinces: [],
  },
  [GeoCountryCode.JP]: {
    code: GeoCountryCode.JP,
    name: 'Japan',
    provinces: [],
  },
  [GeoCountryCode.JE]: {
    code: GeoCountryCode.JE,
    name: 'Jersey',
    provinces: [],
  },
  [GeoCountryCode.JO]: {
    code: GeoCountryCode.JO,
    name: 'Jordan',
    provinces: [],
  },
  [GeoCountryCode.KZ]: {
    code: GeoCountryCode.KZ,
    name: 'Kazakhstan',
    provinces: [],
  },
  [GeoCountryCode.KE]: {
    code: GeoCountryCode.KE,
    name: 'Kenya',
    provinces: [],
  },
  [GeoCountryCode.KI]: {
    code: GeoCountryCode.KI,
    name: 'Kiribati',
    provinces: [],
  },
  [GeoCountryCode.KP]: {
    code: GeoCountryCode.KP,
    name: `Korea, Democratic People's Republic of`,
    provinces: [],
  },
  [GeoCountryCode.KR]: {
    code: GeoCountryCode.KR,
    name: 'Korea, Republic of',
    provinces: [],
  },
  [GeoCountryCode.KW]: {
    code: GeoCountryCode.KW,
    name: 'Kuwait',
    provinces: [],
  },
  [GeoCountryCode.KG]: {
    code: GeoCountryCode.KG,
    name: 'Kyrgyzstan',
    provinces: [],
  },
  [GeoCountryCode.LA]: {
    code: GeoCountryCode.LA,
    name: 'Lao People"s Democratic Republic',
    provinces: [],
  },
  [GeoCountryCode.LV]: {
    code: GeoCountryCode.LV,
    name: 'Latvia',
    provinces: [],
  },
  [GeoCountryCode.LB]: {
    code: GeoCountryCode.LB,
    name: 'Lebanon',
    provinces: [],
  },
  [GeoCountryCode.LS]: {
    code: GeoCountryCode.LS,
    name: 'Lesotho',
    provinces: [],
  },
  [GeoCountryCode.LR]: {
    code: GeoCountryCode.LR,
    name: 'Liberia',
    provinces: [],
  },
  [GeoCountryCode.LY]: {
    code: GeoCountryCode.LY,
    name: 'Libyan Arab Jamahiriya',
    provinces: [],
  },
  [GeoCountryCode.LI]: {
    code: GeoCountryCode.LI,
    name: 'Liechtenstein',
    provinces: [],
  },
  [GeoCountryCode.LT]: {
    code: GeoCountryCode.LT,
    name: 'Lithuania',
    provinces: [],
  },
  [GeoCountryCode.LU]: {
    code: GeoCountryCode.LU,
    name: 'Luxembourg',
    provinces: [],
  },
  [GeoCountryCode.MO]: {
    code: GeoCountryCode.MO,
    name: 'Macao',
    provinces: [],
  },
  [GeoCountryCode.MK]: {
    code: GeoCountryCode.MK,
    name: 'Macedonia, The Former Yugoslav Republic of',
    provinces: [],
  },
  [GeoCountryCode.MG]: {
    code: GeoCountryCode.MG,
    name: 'Madagascar',
    provinces: [],
  },
  [GeoCountryCode.MW]: {
    code: GeoCountryCode.MW,
    name: 'Malawi',
    provinces: [],
  },
  [GeoCountryCode.MY]: {
    code: GeoCountryCode.MY,
    name: 'Malaysia',
    provinces: [],
  },
  [GeoCountryCode.MV]: {
    code: GeoCountryCode.MV,
    name: 'Maldives',
    provinces: [],
  },
  [GeoCountryCode.ML]: { code: GeoCountryCode.ML, name: 'Mali', provinces: [] },
  [GeoCountryCode.MT]: {
    code: GeoCountryCode.MT,
    name: 'Malta',
    provinces: [],
  },
  [GeoCountryCode.MH]: {
    code: GeoCountryCode.MH,
    name: 'Marshall Islands',
    provinces: [],
  },
  [GeoCountryCode.MQ]: {
    code: GeoCountryCode.MQ,
    name: 'Martinique',
    provinces: [],
  },
  [GeoCountryCode.MR]: {
    code: GeoCountryCode.MR,
    name: 'Mauritania',
    provinces: [],
  },
  [GeoCountryCode.MU]: {
    code: GeoCountryCode.MU,
    name: 'Mauritius',
    provinces: [],
  },
  [GeoCountryCode.YT]: {
    code: GeoCountryCode.YT,
    name: 'Mayotte',
    provinces: [],
  },
  [GeoCountryCode.MX]: {
    code: GeoCountryCode.MX,
    name: 'Mexico',
    provinces: [],
  },
  [GeoCountryCode.FM]: {
    code: GeoCountryCode.FM,
    name: 'Micronesia, Federated States of',
    provinces: [],
  },
  [GeoCountryCode.MD]: {
    code: GeoCountryCode.MD,
    name: 'Moldova, Republic of',
    provinces: [],
  },
  [GeoCountryCode.MC]: {
    code: GeoCountryCode.MC,
    name: 'Monaco',
    provinces: [],
  },
  [GeoCountryCode.MN]: {
    code: GeoCountryCode.MN,
    name: 'Mongolia',
    provinces: [],
  },
  [GeoCountryCode.MS]: {
    code: GeoCountryCode.MS,
    name: 'Montserrat',
    provinces: [],
  },
  [GeoCountryCode.MA]: {
    code: GeoCountryCode.MA,
    name: 'Morocco',
    provinces: [],
  },
  [GeoCountryCode.MZ]: {
    code: GeoCountryCode.MZ,
    name: 'Mozambique',
    provinces: [],
  },
  [GeoCountryCode.MM]: {
    code: GeoCountryCode.MM,
    name: 'Myanmar',
    provinces: [],
  },
  [GeoCountryCode.NA]: {
    code: GeoCountryCode.NA,
    name: 'Namibia',
    provinces: [],
  },
  [GeoCountryCode.NR]: {
    code: GeoCountryCode.NR,
    name: 'Nauru',
    provinces: [],
  },
  [GeoCountryCode.NP]: {
    code: GeoCountryCode.NP,
    name: 'Nepal',
    provinces: [],
  },
  [GeoCountryCode.NL]: {
    code: GeoCountryCode.NL,
    name: 'Netherlands',
    provinces: [],
  },
  [GeoCountryCode.AN]: {
    code: GeoCountryCode.AN,
    name: 'Netherlands Antilles',
    provinces: [],
  },
  [GeoCountryCode.NC]: {
    code: GeoCountryCode.NC,
    name: 'New Caledonia',
    provinces: [],
  },
  [GeoCountryCode.NZ]: {
    code: GeoCountryCode.NZ,
    name: 'New Zealand',
    provinces: [],
  },
  [GeoCountryCode.NI]: {
    code: GeoCountryCode.NI,
    name: 'Nicaragua',
    provinces: [],
  },
  [GeoCountryCode.NE]: {
    code: GeoCountryCode.NE,
    name: 'Niger',
    provinces: [],
  },
  [GeoCountryCode.NG]: {
    code: GeoCountryCode.NG,
    name: 'Nigeria',
    provinces: [],
  },
  [GeoCountryCode.NU]: { code: GeoCountryCode.NU, name: 'Niue', provinces: [] },
  [GeoCountryCode.NF]: {
    code: GeoCountryCode.NF,
    name: 'Norfolk Island',
    provinces: [],
  },
  [GeoCountryCode.MP]: {
    code: GeoCountryCode.MP,
    name: 'Northern Mariana Islands',
    provinces: [],
  },
  [GeoCountryCode.NO]: {
    code: GeoCountryCode.NO,
    name: 'Norway',
    provinces: [],
  },
  [GeoCountryCode.OM]: { code: GeoCountryCode.OM, name: 'Oman', provinces: [] },
  [GeoCountryCode.PK]: {
    code: GeoCountryCode.PK,
    name: 'Pakistan',
    provinces: [],
  },
  [GeoCountryCode.PW]: {
    code: GeoCountryCode.PW,
    name: 'Palau',
    provinces: [],
  },
  [GeoCountryCode.PS]: {
    code: GeoCountryCode.PS,
    name: 'Palestinian Territory, Occupied',
    provinces: [],
  },
  [GeoCountryCode.PA]: {
    code: GeoCountryCode.PA,
    name: 'Panama',
    provinces: [],
  },
  [GeoCountryCode.PG]: {
    code: GeoCountryCode.PG,
    name: 'Papua New Guinea',
    provinces: [],
  },
  [GeoCountryCode.PY]: {
    code: GeoCountryCode.PY,
    name: 'Paraguay',
    provinces: [],
  },
  [GeoCountryCode.PE]: { code: GeoCountryCode.PE, name: 'Peru', provinces: [] },
  [GeoCountryCode.PH]: {
    code: GeoCountryCode.PH,
    name: 'Philippines',
    provinces: [],
  },
  [GeoCountryCode.PN]: {
    code: GeoCountryCode.PN,
    name: 'Pitcairn',
    provinces: [],
  },
  [GeoCountryCode.PL]: {
    code: GeoCountryCode.PL,
    name: 'Poland',
    provinces: [],
  },
  [GeoCountryCode.PT]: {
    code: GeoCountryCode.PT,
    name: 'Portugal',
    provinces: [],
  },
  [GeoCountryCode.PR]: {
    code: GeoCountryCode.PR,
    name: 'Puerto Rico',
    provinces: [],
  },
  [GeoCountryCode.QA]: {
    code: GeoCountryCode.QA,
    name: 'Qatar',
    provinces: [],
  },
  [GeoCountryCode.RE]: {
    code: GeoCountryCode.RE,
    name: 'Reunion',
    provinces: [],
  },
  [GeoCountryCode.RO]: {
    code: GeoCountryCode.RO,
    name: 'Romania',
    provinces: [],
  },
  [GeoCountryCode.RU]: {
    code: GeoCountryCode.RU,
    name: 'Russian Federation',
    provinces: [],
  },
  [GeoCountryCode.RW]: {
    code: GeoCountryCode.RW,
    name: 'RWANDA',
    provinces: [],
  },
  [GeoCountryCode.SH]: {
    code: GeoCountryCode.SH,
    name: 'Saint Helena',
    provinces: [],
  },
  [GeoCountryCode.KN]: {
    code: GeoCountryCode.KN,
    name: 'Saint Kitts and Nevis',
    provinces: [],
  },
  [GeoCountryCode.LC]: {
    code: GeoCountryCode.LC,
    name: 'Saint Lucia',
    provinces: [],
  },
  [GeoCountryCode.PM]: {
    code: GeoCountryCode.PM,
    name: 'Saint Pierre and Miquelon',
    provinces: [],
  },
  [GeoCountryCode.VC]: {
    code: GeoCountryCode.VC,
    name: 'Saint Vincent and the Grenadines',
    provinces: [],
  },
  [GeoCountryCode.WS]: {
    code: GeoCountryCode.WS,
    name: 'Samoa',
    provinces: [],
  },
  [GeoCountryCode.SM]: {
    code: GeoCountryCode.SM,
    name: 'San Marino',
    provinces: [],
  },
  [GeoCountryCode.ST]: {
    code: GeoCountryCode.ST,
    name: 'Sao Tome and Principe',
    provinces: [],
  },
  [GeoCountryCode.SA]: {
    code: GeoCountryCode.SA,
    name: 'Saudi Arabia',
    provinces: [],
  },
  [GeoCountryCode.SN]: {
    code: GeoCountryCode.SN,
    name: 'Senegal',
    provinces: [],
  },
  [GeoCountryCode.CS]: {
    code: GeoCountryCode.CS,
    name: 'Serbia and Montenegro',
    provinces: [],
  },
  [GeoCountryCode.SC]: {
    code: GeoCountryCode.SC,
    name: 'Seychelles',
    provinces: [],
  },
  [GeoCountryCode.SL]: {
    code: GeoCountryCode.SL,
    name: 'Sierra Leone',
    provinces: [],
  },
  [GeoCountryCode.SG]: {
    code: GeoCountryCode.SG,
    name: 'Singapore',
    provinces: [],
  },
  [GeoCountryCode.SK]: {
    code: GeoCountryCode.SK,
    name: 'Slovakia',
    provinces: [],
  },
  [GeoCountryCode.SI]: {
    code: GeoCountryCode.SI,
    name: 'Slovenia',
    provinces: [],
  },
  [GeoCountryCode.SB]: {
    code: GeoCountryCode.SB,
    name: 'Solomon Islands',
    provinces: [],
  },
  [GeoCountryCode.SO]: {
    code: GeoCountryCode.SO,
    name: 'Somalia',
    provinces: [],
  },
  [GeoCountryCode.ZA]: {
    code: GeoCountryCode.ZA,
    name: 'South Africa',
    provinces: [],
  },
  [GeoCountryCode.GS]: {
    code: GeoCountryCode.GS,
    name: 'South Georgia and the South Sandwich Islands',
    provinces: [],
  },
  [GeoCountryCode.ES]: {
    code: GeoCountryCode.ES,
    name: 'Spain',
    provinces: [],
  },
  [GeoCountryCode.LK]: {
    code: GeoCountryCode.LK,
    name: 'Sri Lanka',
    provinces: [],
  },
  [GeoCountryCode.SD]: {
    code: GeoCountryCode.SD,
    name: 'Sudan',
    provinces: [],
  },
  [GeoCountryCode.SR]: {
    code: GeoCountryCode.SR,
    name: 'Suriname',
    provinces: [],
  },
  [GeoCountryCode.SJ]: {
    code: GeoCountryCode.SJ,
    name: 'Svalbard and Jan Mayen',
    provinces: [],
  },
  [GeoCountryCode.SZ]: {
    code: GeoCountryCode.SZ,
    name: 'Swaziland',
    provinces: [],
  },
  [GeoCountryCode.SE]: {
    code: GeoCountryCode.SE,
    name: 'Sweden',
    provinces: [],
  },
  [GeoCountryCode.CH]: {
    code: GeoCountryCode.CH,
    name: 'Switzerland',
    provinces: [],
  },
  [GeoCountryCode.SY]: {
    code: GeoCountryCode.SY,
    name: 'Syrian Arab Republic',
    provinces: [],
  },
  [GeoCountryCode.TW]: {
    code: GeoCountryCode.TW,
    name: 'Taiwan',
    provinces: [],
  },
  [GeoCountryCode.TJ]: {
    code: GeoCountryCode.TJ,
    name: 'Tajikistan',
    provinces: [],
  },
  [GeoCountryCode.TZ]: {
    code: GeoCountryCode.TZ,
    name: 'Tanzania, United Republic of',
    provinces: [],
  },
  [GeoCountryCode.TH]: {
    code: GeoCountryCode.TH,
    name: 'Thailand',
    provinces: [],
  },
  [GeoCountryCode.TL]: {
    code: GeoCountryCode.TL,
    name: 'Timor-Leste',
    provinces: [],
  },
  [GeoCountryCode.TG]: { code: GeoCountryCode.TG, name: 'Togo', provinces: [] },
  [GeoCountryCode.TK]: {
    code: GeoCountryCode.TK,
    name: 'Tokelau',
    provinces: [],
  },
  [GeoCountryCode.TO]: {
    code: GeoCountryCode.TO,
    name: 'Tonga',
    provinces: [],
  },
  [GeoCountryCode.TT]: {
    code: GeoCountryCode.TT,
    name: 'Trinidad and Tobago',
    provinces: [],
  },
  [GeoCountryCode.TN]: {
    code: GeoCountryCode.TN,
    name: 'Tunisia',
    provinces: [],
  },
  [GeoCountryCode.TR]: {
    code: GeoCountryCode.TR,
    name: 'Turkey',
    provinces: [],
  },
  [GeoCountryCode.TM]: {
    code: GeoCountryCode.TM,
    name: 'Turkmenistan',
    provinces: [],
  },
  [GeoCountryCode.TC]: {
    code: GeoCountryCode.TC,
    name: 'Turks and Caicos Islands',
    provinces: [],
  },
  [GeoCountryCode.TV]: {
    code: GeoCountryCode.TV,
    name: 'Tuvalu',
    provinces: [],
  },
  [GeoCountryCode.UG]: {
    code: GeoCountryCode.UG,
    name: 'Uganda',
    provinces: [],
  },
  [GeoCountryCode.UA]: {
    code: GeoCountryCode.UA,
    name: 'Ukraine',
    provinces: [],
  },
  [GeoCountryCode.AE]: {
    code: GeoCountryCode.AE,
    name: 'United Arab Emirates',
    provinces: [],
  },
  [GeoCountryCode.GB]: {
    code: GeoCountryCode.GB,
    name: 'United Kingdom',
    provinces: [],
  },
  [GeoCountryCode.US]: {
    code: GeoCountryCode.US,
    name: 'United States',
    provinces: [],
  },
  [GeoCountryCode.UM]: {
    code: GeoCountryCode.UM,
    name: 'United States Minor Outlying Islands',
    provinces: [],
  },
  [GeoCountryCode.UY]: {
    code: GeoCountryCode.UY,
    name: 'Uruguay',
    provinces: [],
  },
  [GeoCountryCode.UZ]: {
    code: GeoCountryCode.UZ,
    name: 'Uzbekistan',
    provinces: [],
  },
  [GeoCountryCode.VU]: {
    code: GeoCountryCode.VU,
    name: 'Vanuatu',
    provinces: [],
  },
  [GeoCountryCode.VE]: {
    code: GeoCountryCode.VE,
    name: 'Venezuela',
    provinces: [],
  },
  [GeoCountryCode.VN]: {
    code: GeoCountryCode.VN,
    name: 'Viet Nam',
    provinces: [],
  },
  [GeoCountryCode.VG]: {
    code: GeoCountryCode.VG,
    name: 'Virgin Islands, British',
    provinces: [],
  },
  [GeoCountryCode.VI]: {
    code: GeoCountryCode.VI,
    name: 'Virgin Islands, U.S.',
    provinces: [],
  },
  [GeoCountryCode.WF]: {
    code: GeoCountryCode.WF,
    name: 'Wallis and Futuna',
    provinces: [],
  },
  [GeoCountryCode.EH]: {
    code: GeoCountryCode.EH,
    name: 'Western Sahara',
    provinces: [],
  },
  [GeoCountryCode.YE]: {
    code: GeoCountryCode.YE,
    name: 'Yemen',
    provinces: [],
  },
  [GeoCountryCode.ZM]: {
    code: GeoCountryCode.ZM,
    name: 'Zambia',
    provinces: [],
  },
  [GeoCountryCode.ZW]: {
    code: GeoCountryCode.ZW,
    name: 'Zimbabwe',
    provinces: [],
  },
} as const satisfies Record<GeoCountryCode, CountryCode>;
