import { Form } from 'react-router-dom';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { InputPrimitive } from '~/components/form-controls/input';
import { useIsLoading } from '~/utils/routing';
import { ClaimType } from '~/utils/types';
import { INPUT_KEY, useQuantityActionResult } from './quantity-action';
import { useQuantityData } from './quantity-loader';

export default function ClaimQuantity() {
  const { remainingReturnableQuantity, quantity, claimType } =
    useQuantityData();
  const actionResult = useQuantityActionResult();
  const isLoading = useIsLoading();

  const headline = [
    claimType === ClaimType.return && `How many do you need to return?`,
    claimType === ClaimType.warranty &&
      `How many of this product do you need help with?`,
    `(${remainingReturnableQuantity} available)`,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Card headline={headline}>
      <Form method="post" className="flex flex-col" name="claimQuantity">
        <InputPrimitive
          required
          className="py-2"
          id="quantity"
          name={INPUT_KEY}
          type="number"
          defaultValue={quantity}
          min="1"
          max={remainingReturnableQuantity}
          validationMessages={{
            valueMissing: 'Please enter a quantity',
            rangeUnderflow: 'Quantity must be at least 1',
            rangeOverflow: `Quantity must be at most ${remainingReturnableQuantity}`,
          }}
        />
        {actionResult?.ok === false && (
          <Alert variant="error" title="Problem saving quantity">
            {actionResult.message}
          </Alert>
        )}
        <Submit className="mt-6" loading={isLoading}>
          Next
        </Submit>
      </Form>
    </Card>
  );
}
