import { z } from 'zod';
import api from '~/utils/api';
import { formatDate } from '~/utils/formatters';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { GspContext, Order, ProtectionClaim } from '~/utils/types';

export enum EntryPoint {
  new = 'new',
  existing = 'existing',
  deepLink = 'deepLink',
}

export type LoaderData = {
  id: number;
  status: ProtectionClaim['status'];
  createdOn: string;
  entryPoint: EntryPoint;
};

const schema = z.object({
  idFromPlatform: z.string(),
  claimId: z.coerce.number(),
  store: z.string().default(''),
  entryPoint: z
    .union([z.literal(EntryPoint.new), z.literal(EntryPoint.existing)])
    .optional(),
});

const getOrder = (context: GspContext, query: { idFromPlatform: string }) =>
  context.order?.idFromPlatform === query.idFromPlatform ?
    Promise.resolve(context.order)
  : api
      .fetchProtectionOrder({ query })
      .then((order) => context.setOrder(order));

const checkOrderStore = (store: string) => (order: Order) =>
  store === '' || order.storeSlug === store ?
    Promise.resolve(order)
  : Promise.reject(new Error('Order not from store'));

const getClaim = (claimId: number) => (order: Order) => {
  const claim = order.shippingClaims?.find((c) => c.id === claimId);

  return claim ?
      Promise.resolve(claim)
    : Promise.reject(new Error('Claim not found'));
};

export default gspLoader(async ({ params, context }) => {
  const { idFromPlatform, claimId, entryPoint, store } = schema.parse(params);

  return (
    getOrder(context, { idFromPlatform })
      // ! ensure that the order is from the store we are on if we are on a branded reorder path
      .then(checkOrderStore(store))
      .then(getClaim(claimId))
      .then((claim) =>
        json<LoaderData>({
          id: claim.id,
          status: claim.status,
          createdOn: formatDate(claim.createdOn),
          entryPoint: entryPoint ?? EntryPoint.deepLink,
        }),
      )
      .catch(() => redirect(`/${store}`))
  );
});

export const useProtectionClaimLoaderData = createLoaderDataHook<LoaderData>();
