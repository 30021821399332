import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
} from '~/utils/routing';
import { crewOrderLineItemSchema } from '~/utils/schemas';
import { ActionResult, ClaimType, CrewContext } from '~/utils/types';

export const FormKeys = {
  claimId: 'claimId',
  lineItem: 'lineItem',
  claimType: 'claimType',
} as const;

const deleteSchema = zfd.formData({
  [FormKeys.claimId]: zfd.text(),
});

const handleDelete = (formData: FormData, context: CrewContext) => {
  const id = deleteSchema.parse(formData).claimId;
  const ok = context.removeLineItemClaim(id);

  return json<ActionResult>({
    ok,
    message: ok ? 'Item removed' : `Unable to cancel selected claim.`,
  });
};

const postSchema = zfd.formData({
  [FormKeys.claimType]: zfd.text(z.nativeEnum(ClaimType)),
  [FormKeys.lineItem]: zfd.json(crewOrderLineItemSchema),
});

const handlePost = (formData: FormData, context: CrewContext) => {
  const result = postSchema.safeParse(formData);

  if (!result.success) {
    return error(
      new Error('Malformed form data', {
        cause: {
          error: result.error,
          entries: Array.from(formData.entries()),
        },
      }),
    );
  }

  const { claimType, lineItem } = result.data;

  const id = Math.random().toString(36).slice(2);

  if (!lineItem) {
    return json<ActionResult>({
      ok: false,
      message: 'Selected item not found',
    });
  }

  context.setDraftClaim({
    id,
    claimType,
    lineItem,
    quantity: 1,
    unitPrice: lineItem.unitPrice,
    unitPriceDisplay: lineItem.unitPriceDisplay,
    unitTax: lineItem.unitTax,
    unitListPrice: lineItem.unitListPrice,
    grams: lineItem.grams,
    customFields: {},
    comments: null,
    originalStoreOrderLineItemId: lineItem.id,
  });

  return redirect(`${id}/quantity`, StatusCodes.SEE_OTHER);
};

export default crewAction(({ formData, context, request }) => {
  if (request.method === 'DELETE') {
    return handleDelete(formData, context);
  }

  if (request.method === 'POST') {
    return handlePost(formData, context);
  }

  return error(
    new Error('Invalid request method', { cause: { method: request.method } }),
  );
});

export const useOrderClaimActionResult = createActionResultHook<ActionResult>();
