import { z } from 'zod';
import { getClaimReasons } from '~/utils/claim-line-items';
import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import type { DraftLineItemClaim } from '~/utils/types';

export type ClaimReasonDetailData = {
  reasonDetailOptions: { id: number; name: string }[];
  claim: DraftLineItemClaim;
};

const paramsSchema = z.object({
  reasonId: z.coerce.number().int().positive(),
});

export default crewLoader(
  withDraftClaim(({ params, claim }) => {
    const claimReasons = getClaimReasons(claim);
    const { reasonId } = paramsSchema.parse(params);
    const reason = claimReasons.find(({ id }) => id === reasonId);

    if (!reason) {
      return error(
        new Error(
          'Invalid reason ID: does not match any claim reasons for claim type',
        ),
      );
    }

    const reasonDetailOptions = reason.claimReasonDetails.map(
      ({ id, name }) => ({
        name,
        id,
      }),
    );

    // if there are no detail reasons, skip this step
    if (!reasonDetailOptions.length) {
      return redirect('../follow-up');
    }

    return json<ClaimReasonDetailData>({ reasonDetailOptions, claim });
  }),
);

export const useReasonDetailData =
  createLoaderDataHook<ClaimReasonDetailData>();
