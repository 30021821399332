import {
  createLoaderDataHook,
  json,
  registrationLoader,
} from '~/utils/routing';
import { DraftRegistration, SalesChannels } from '~/utils/types';

export type RegisterLoaderData = {
  shouldCollectProofOfPurchase: boolean;
  storeSalesChannels: SalesChannels;
  email: DraftRegistration['email'];
  proofOfPurchaseAssets: NonNullable<
    DraftRegistration['proofOfPurchaseAssets']
  >;
  estimatedPurchaseDate?: DraftRegistration['estimatedPurchaseDate'];
  storeSalesChannel?: DraftRegistration['storeSalesChannel'];
  shouldCollectAddress: boolean;
  address: DraftRegistration['address'] | null;
  orderNumber?: DraftRegistration['orderNumber'];
};

export default registrationLoader(({ context }) => {
  const {
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    shouldCollectAddress,
  } = context.settings.registrations;

  const {
    address = null,
    email = '',
    proofOfPurchaseAssets,
    estimatedPurchaseDate,
    storeSalesChannel,
    orderNumber,
  } = context.draftRegistration ?? {};

  return json<RegisterLoaderData>({
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    shouldCollectAddress,
    address,
    email,
    proofOfPurchaseAssets: proofOfPurchaseAssets ?? [],
    estimatedPurchaseDate,
    storeSalesChannel,
    orderNumber,
  });
});

export const useRegisterData = createLoaderDataHook<RegisterLoaderData>();
