import { not } from '~/utils/compute';
import { CrewOrderLineItem, LineItemClaim } from '~/utils/types';

export const hasEligibleReturnResolution = (item: CrewOrderLineItem) =>
  item.return.exchange.isEligible ||
  item.return.refund.isEligible ||
  item.return.giftCard.isEligible;

export const isItemReturnEligible =
  (areReturnsEnabled: boolean) => (item: CrewOrderLineItem) =>
    areReturnsEnabled &&
    !item.return.isFinalSale &&
    item.remainingReturnableQuantity > 0 &&
    hasEligibleReturnResolution(item);

export const includedInClaim = (
  item: CrewOrderLineItem,
  claims: LineItemClaim[],
) => Boolean(claims.find((claim) => claim.lineItem.id === item.id));

export const isItemWarrantyEligible = (item: CrewOrderLineItem) =>
  !item.return.isFinalSale &&
  item.warranty.isEligible &&
  item.remainingReturnableQuantity > 0;

export const checkIfPastReturnWindow = (areReturnsEnabled: boolean) =>
  not(isItemReturnEligible(areReturnsEnabled));
