import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { ClaimType, CrewOrderLineItem, LineItemClaim } from '~/utils/types';

import { z } from 'zod';
import {
  includedInClaim,
  isItemReturnEligible,
  isItemWarrantyEligible,
} from '~/app/[:store]/order/[:idFromPlatform]/order-loader-utils';

export type OrderClaimLoaderData = {
  lastActionableDate?: string;
  claimType: ClaimType;
  lineItems: CrewOrderLineItem[];
  pendingClaims: LineItemClaim[];
  ineligibleItems: CrewOrderLineItem[];
};

const sumPendingClaimQuantities = (claims: LineItemClaim[]) =>
  claims.reduce((sums, { lineItem: { id }, quantity = 0 }) => {
    const currentSum = sums.get(id) ?? 0;
    sums.set(id, currentSum + quantity);
    return sums;
  }, new Map<number, number>());

const updateRemainingReturnableQuantity = (pendingClaims: LineItemClaim[]) => {
  const pendingClaimQuantities = sumPendingClaimQuantities(pendingClaims);

  return (item: CrewOrderLineItem) => {
    const { currentQuantity, id } = item;
    const pendingClaimQuantity = pendingClaimQuantities.get(id) ?? 0;

    return {
      ...item,
      remainingReturnableQuantity: currentQuantity - pendingClaimQuantity,
    } satisfies CrewOrderLineItem;
  };
};

const paramsSchema = z.object({
  idFromPlatform: z.string(),
  claimType: z.nativeEnum(ClaimType),
});

export default crewLoader(({ params, context }) => {
  const { idFromPlatform, claimType } = paramsSchema.parse(params);
  const {
    lineItemClaims: pendingClaims,
    settings: {
      return: { isEnabled: areReturnsEnabled },
    },
    order,
  } = context;

  if (!order || idFromPlatform !== order.idFromPlatform) {
    return redirect('..');
  }

  const lineItems = order.lineItems.map(
    updateRemainingReturnableQuantity(pendingClaims),
  );

  const isEligible =
    claimType === ClaimType.warranty ?
      isItemWarrantyEligible
    : isItemReturnEligible(areReturnsEnabled);

  const eligibleLineItems = lineItems.filter(isEligible);
  const lastActionableDate =
    claimType === ClaimType.warranty ? order.warrantyUntil : order.returnUntil;

  return json<OrderClaimLoaderData>({
    lastActionableDate,
    claimType,
    lineItems: eligibleLineItems,
    pendingClaims,
    ineligibleItems: lineItems.filter(
      (item) => !isEligible(item) && !includedInClaim(item, pendingClaims),
    ),
  });
});

export const useOrderClaimData = createLoaderDataHook<OrderClaimLoaderData>();
