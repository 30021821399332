import { z } from 'zod';
import { ShipmentMethod } from '../../enums/crew.js';
import { ShipmentApiProviders } from '../../enums/enum.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';

export const rate = z.object({
  id: z.string(),
  carrier: z.string(),
  cost: z.number(),
});

const quoteBase = z.object({
  iconImgUrl: z.string(),
  storeRuleId: z.number().nullable(),
  returnLocation: z.object({
    id: z.number(),
  }),
});

const labelReturnMethod = z
  .object({
    type: z.literal(ShipmentMethod.label),
    provider: enumToZodLiteralUnion(ShipmentApiProviders),
    shipmentFromPlatform: z.object({
      id: z.string(),
      rate,
    }),
  })
  .merge(quoteBase);

export const packingSlipReturnMethod = z
  .object({
    type: z.literal(ShipmentMethod.packingSlip),
  })
  .merge(quoteBase);

export const returnShipmentMethod = z.discriminatedUnion('type', [
  packingSlipReturnMethod,
  labelReturnMethod,
]);
