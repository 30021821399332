import { crewClaimResolutionMethodEnumName } from 'corso-types/enums/crew';
import { useId, useMemo } from 'react';
import { formatProductOptions } from '~/utils/formatters';
import {
  ClaimLineItem as ClaimLineItemType,
  ResolutionMethod,
  RollupStatusCode,
} from '~/utils/types';
import Chip from './chip';
import ProductImage from './product-image';

type Props = {
  price: string;
  quantity: number;
  status?: string;
  statusDetail?: string;
  name?: string | null;
  imgUrl?: string | null;
  className?: string;
  rollupStatus?: `${RollupStatusCode}`;
  requestedResolution: `${ResolutionMethod}`;
  claimLineItemNoteToCustomer?: string;

  productOptions?: ClaimLineItemType['originalStoreOrderLineItem']['optionsFromPlatform'];
};

export default function ClaimLineItem({
  name,
  price,
  imgUrl,
  quantity,
  status,
  statusDetail,
  className,
  requestedResolution,
  rollupStatus,
  claimLineItemNoteToCustomer,

  productOptions = [],
}: Props) {
  const labelId = useId();

  const description = useMemo(
    () => formatProductOptions(productOptions),
    [productOptions],
  );

  return (
    <article className={className} aria-labelledby={labelId}>
      <div className="flex gap-4">
        <ProductImage
          variant="small"
          name={name}
          src={imgUrl}
          quantity={quantity}
        />
        <div className="flex w-full flex-col gap-1 text-sm font-medium text-gray-500">
          <div>
            <div className="flex w-full justify-between text-gray-800">
              <span id={labelId}>{name}</span>

              <span className={`${status === 'Denied' ? 'line-through' : ''}`}>
                {price}
              </span>
            </div>
            <span>{description}</span>
          </div>
          <div className="flex gap-1">
            <Chip>
              Request: {crewClaimResolutionMethodEnumName[requestedResolution]}
            </Chip>
            {status && rollupStatus === 'Completed' && (
              <Chip>Status: {status}</Chip>
            )}
            {statusDetail && <Chip>{statusDetail}</Chip>}
          </div>

          {claimLineItemNoteToCustomer && (
            <p className="my-3 text-xs">
              <span className="font-bold">Note: </span>
              <span>{claimLineItemNoteToCustomer}</span>
            </p>
          )}
        </div>
      </div>
    </article>
  );
}
