import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { getClaimReasons } from '~/utils/claim-line-items';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export const INPUT_NAME = 'claimReasonDetailId';

const makeFormSchema = (reason: ReturnType<typeof getClaimReasons>[number]) => {
  const ids = reason.claimReasonDetails.map(({ id }) => id);

  return zfd.formData({
    [INPUT_NAME]: zfd.numeric(
      z
        .number({ message: 'Please choose a reason.' })
        .int({ message: 'Invalid reason ID.' })
        .positive({ message: 'Invalid reason ID.' })
        .refine((id) => ids.includes(id), { message: 'Invalid reason ID.' }),
    ),
  });
};

export default crewAction(
  withDraftClaim(({ context, claim, formData }) => {
    if (!claim.reason) {
      return error(new Error('Missing claim reason.'));
    }

    const reason = getClaimReasons(claim).find(
      ({ id }) => id === claim.reason?.id,
    );

    if (!reason) {
      return error(new Error('Invalid claim reason.'));
    }

    const formSchema = makeFormSchema(reason);
    const result = formSchema.safeParse(formData);

    if (!result.success) {
      return json<ActionResult>({
        ok: false,
        message: result.error.message,
      });
    }

    const { claimReasonDetailId } = result.data;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- validated by form schema
    const reasonDetail = reason.claimReasonDetails.find(
      ({ id }) => id === claimReasonDetailId,
    )!;

    context.setDraftClaim({
      ...claim,
      reason: {
        ...claim.reason,
        detail: { id: reasonDetail.id, name: reasonDetail.name },
      },
    });

    return redirect('../follow-up', StatusCodes.SEE_OTHER);
  }),
);

export const useReasonDetailActionResult =
  createActionResultHook<ActionResult<typeof INPUT_NAME>>();
