import { StatusCodes } from 'http-status-codes';
import api from '~/utils/api';
import { pendingLineItemClaimToAutomationLineItem } from '~/utils/claim-line-items';
import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  redirect,
} from '~/utils/routing';
import { ClaimType, ReturnShipmentType, ShipmentMethod } from '~/utils/types';

export type ReturnMethodsData = {
  methods: (ShipmentMethod & {
    label: string;
    description: string;
  })[];
};

const textNameMap = {
  [ReturnShipmentType.packingSlip]: 'Pack & Ship',
  [ReturnShipmentType.label]: 'Use Provided Label',
} satisfies Record<ReturnShipmentType, string>;

const detailText = {
  [ReturnShipmentType.packingSlip]: 'Box & Label Needed',
  [ReturnShipmentType.label]: 'Box Needed',
} satisfies Record<ReturnShipmentType, string>;

const getLabel = (method: ShipmentMethod) =>
  method.type === ReturnShipmentType.label ?
    `${textNameMap[method.type]} - (${
      method.shipmentFromPlatform.rate.carrier
    })`
  : textNameMap[method.type];

export default crewLoader(({ params: { store }, context }) => {
  if (!context.order) {
    return redirect(`/${store}`, StatusCodes.MOVED_TEMPORARILY);
  }

  if (!context.lineItemClaims.length) {
    return redirect(`/${store}/order/${context.order.idFromPlatform}`);
  }

  if (context.address === null) {
    return error(new Error('Missing shipping address'));
  }

  const {
    lineItemClaims,
    address,
    order: { id },
    settings: { storefrontId },
  } = context;

  return api
    .createShipmentMethodsQuote({
      params: {
        storefrontId,
      },
      body: {
        originalStoreOrderId: id,
        address,
        lineItems: lineItemClaims.map((item) => ({
          ...pendingLineItemClaimToAutomationLineItem(item),
          grams: item.grams * item.quantity,
        })),
      },
    })
    .then(({ toCustomer, fromCustomer }) => {
      if (toCustomer) {
        context.setToCustomerRate(toCustomer);
      }

      if (fromCustomer.length === 1 && fromCustomer[0]) {
        context.setReturnMethod(fromCustomer[0]);
        const reviewPage =
          (
            context.lineItemClaims.some(
              (claim) => claim.claimType === ClaimType.warranty,
            )
          ) ?
            `../review/warranty`
          : `../review/return`;

        return redirect(reviewPage, StatusCodes.SEE_OTHER);
      }

      return json<ReturnMethodsData>({
        methods: fromCustomer
          .sort((a) => (a.type === ReturnShipmentType.label ? -1 : 1))
          .map((method) => ({
            ...method,
            label: getLabel(method),
            description: detailText[method.type],
          })),
      });
    });
});

export const useReturnMethodsData = createLoaderDataHook<ReturnMethodsData>();
