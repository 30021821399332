import {
  CrewClaimReasonCategoryCode,
  CrewClaimResolutionMethodEnum,
  CrewClaimTypeEnum,
  CrewUi,
  enumToZodLiteralUnion,
} from 'corso-types';
import { z } from 'zod';

export const {
  claimAddressSchema,
  shipmentCreateSchema,
  claimSchema,
  shipmentMethodSchema,
  claimLineItemCreateSchema,
} = CrewUi;

export const errorMessage = z.object({
  message: z.string(),
});

const zodError = errorMessage.extend({
  issues: z.array(
    z.object({
      code: z.string(),
      path: z.array(z.string().or(z.number())),
      message: z.string(),
    }),
  ),
});
export const isErrorMessage = (
  obj: unknown,
): obj is z.infer<typeof errorMessage> => errorMessage.safeParse(obj).success;

export const isZodError = (obj: unknown): obj is z.infer<typeof zodError> =>
  zodError.safeParse(obj).success;

export function isApiError(
  body: unknown,
): body is z.infer<typeof errorMessage> {
  return errorMessage.safeParse(body).success;
}

export const mediaAssetSchema = z.object({
  src: z.string(),
  name: z.string(),
  type: z.enum(['image', 'video', 'document', 'audio', 'other', 'pdf']),
});

export const stringToJSONSchema = z.string().transform((str, ctx) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
    return z.NEVER;
  }
});

export const crewOrderLineItemSchema = CrewUi.crewOrderLineItemSchema.extend({
  remainingReturnableQuantity: z.number(),
  unitPriceDisplay: z.string(),
});

const lineItemClaimReasonSchema = z.object({
  id: z.number(),
  name: z.string(),
  category: enumToZodLiteralUnion(CrewClaimReasonCategoryCode),
  detail: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
});

export const exchangeVariantSchema = CrewUi.variantExchangeOptionsSchema;

export const draftLineItemClaimSchema = CrewUi.claimLineItemCreateSchema
  .omit({
    images: true,
    reason: true,
    customFields: true,
    requestedResolutionMethodEnum: true,
  })
  .extend({
    id: z.string(),
    claimType: z.nativeEnum(CrewClaimTypeEnum),
    lineItem: crewOrderLineItemSchema,
    quantity: z.number(),
    variant: exchangeVariantSchema.optional(),
    unitPrice: z.number(),
    unitPriceDisplay: z.string(),
    unitTax: z.number(),
    unitListPrice: z.number().optional(),
    customFields: z.record(z.string()),
    grams: z.number(),
    assets: z.array(mediaAssetSchema).optional(),
    requestedResolutionMethodEnum: z
      .enum([
        CrewClaimResolutionMethodEnum.refund,
        CrewClaimResolutionMethodEnum.variantExchange,
        CrewClaimResolutionMethodEnum.giftCard,
        CrewClaimResolutionMethodEnum.warrantyReview,
      ])
      .optional(),
    reason: lineItemClaimReasonSchema.optional(),
  });

export const variantExchangeClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(
      CrewClaimResolutionMethodEnum.variantExchange,
    ),
    variant: CrewUi.variantExchangeOptionsSchema,
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(CrewClaimTypeEnum.return),
  });

export const refundClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(
      CrewClaimResolutionMethodEnum.refund,
    ),

    variant: z.never().optional(),
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(CrewClaimTypeEnum.return),
  });

export const giftCardClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(
      CrewClaimResolutionMethodEnum.giftCard,
    ),
    variant: z.never().optional(),
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(CrewClaimTypeEnum.return),
  });

export const warrantyClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(
      CrewClaimResolutionMethodEnum.warrantyReview,
    ),
    variant: z.never().optional(),
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(CrewClaimTypeEnum.warranty),
  });

export const lineItemClaimSchema = z.discriminatedUnion(
  'requestedResolutionMethodEnum',
  [
    variantExchangeClaimSchema,
    refundClaimSchema,
    giftCardClaimSchema,
    warrantyClaimSchema,
  ],
);

export const crewOrderSchema = CrewUi.orderSchema.extend({
  lineItems: z.array(crewOrderLineItemSchema),
});

export const crewStateSchema = z.object({
  settings: CrewUi.settingsSchema,
  claim: CrewUi.claimSchema.nullable(),
  draftClaim: draftLineItemClaimSchema.nullable(),
  lineItemClaims: z.array(lineItemClaimSchema),
  order: crewOrderSchema.nullable(),
  address: CrewUi.claimAddressSchema.nullable(),
  toCustomerRate: CrewUi.shipmentRateSchema.nullable(),
  returnMethod: CrewUi.shipmentMethodSchema.nullable(),
});

export const storeSalesChannelSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const draftRegistrationSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  storeSalesChannel: storeSalesChannelSchema,
  estimatedPurchaseDate: z.string(),
  address: z.nullable(CrewUi.claimAddressSchema),
  proofOfPurchaseAssets: z.array(mediaAssetSchema).nullable(),
  storeProductVariantIds: z.array(z.number()).min(1).optional(),
  orderNumber: z.string().optional(),
});

export const registrationStateSchema = z.object({
  settings: CrewUi.settingsSchema,
  draftRegistration: draftRegistrationSchema.nullable(),
});
