import { Form } from 'react-router-dom';
import AddressFields from '~/components/address-fields';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Page from '~/components/page';
import { useEditAddressActionResult } from './edit-action';
import { useEditAddressData } from './edit-loader';

// TODO: Move to 2 column layout like register page
export default function EditAddress() {
  const { address } = useEditAddressData();
  const actionResult = useEditAddressActionResult();
  const errors = actionResult?.ok === false ? actionResult.form : undefined;

  return (
    <Page headline="Update Address">
      <Card>
        <Form method="post" className="flex flex-col gap-6">
          <AddressFields address={address} errors={errors} />
          <Submit>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
