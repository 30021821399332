import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Card from '~/components/card';
import OrderLookupForm from '~/components/order-lookup-form';
import Page from '~/components/page';
import { FormKeys, useOrderLookupActionResult } from './crew-lookup-action';
import { useOrderLookupData } from './crew-lookup-loader';

function OrderLookupDetailText({
  orderLookupDetailText,
}: {
  orderLookupDetailText: string | undefined;
}) {
  if (!orderLookupDetailText) return null;
  return (
    <div className="flex items-start gap-2 border-none text-gray-800">
      <InformationCircleIcon className="h-6 w-6 shrink-0" />
      {orderLookupDetailText}
    </div>
  );
}

export default function OrderLookup() {
  const actionResult = useOrderLookupActionResult();
  const { exchangeOrderPrefix, orderLookupDetailText, orderLookupLabel } =
    useOrderLookupData();

  return (
    <Page headline="Let's find your order">
      <Card>
        <OrderLookupDetailText orderLookupDetailText={orderLookupDetailText} />
        <OrderLookupForm
          lookupIdentifierName={FormKeys.EmailOrPostalCode}
          orderNumberName={FormKeys.OrderNo}
          orderLookupLabel={orderLookupLabel}
          exchangeOrderPrefix={exchangeOrderPrefix}
          errorMessage={
            actionResult?.ok === false ? actionResult.message : undefined
          }
        />
      </Card>
    </Page>
  );
}
