import { z } from 'zod';
import { ShipmentMethod } from '../../enums/crew.js';
import { TrackingStatusCode } from '../../enums/tracking.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';
import { locationAddress } from './address.js';
import { claimLineItem } from './claimLineItem.js';
import { rate, returnShipmentMethod } from './shipmentRate.js';
import { carrier } from './tracking.js';

// ? new shipment related types

export const shipmentCreate = z.object({
  claimId: z.number(),
  returnShipment: returnShipmentMethod,
});

export const returnShipmentTracker = z.object({
  latestStatus: enumToZodLiteralUnion(TrackingStatusCode),
  carrier,
});

export const shipment = z.object({
  createdOn: z.string(),
  shipmentExpiresOn: z.string(),
  returnShipmentId: z.number(),
  toAddress: locationAddress,
  fromAddress: locationAddress,
  inTransitOn: z.string().optional(),
  isReturnShipment: z.boolean(),
  qrCodeDetail: z
    .object({
      url: z.string(),
      returnToName: z.string(),
      carrier: z.string(),
      carrierIconImgUrl: z.string(),
    })
    .optional(),
  returnShipmentPdfAssetUrl: z.string().optional(),
  returnShipmentType: enumToZodLiteralUnion(ShipmentMethod),
  claimLineItems: z.array(claimLineItem),
  tracker: returnShipmentTracker.optional(),
  rmaId: z.string().optional(),
  rmaNumber: z.string().optional(),
  rmaProvider: z
    .union([
      z.literal('Ship_Bob'),
      z.literal('Ship_Hero'),
      z.literal('Blue_Box'),
    ])
    .optional(),
});

// used for quoting "new shipping estimates" on a new order
export const shipmentQuote = z.object({
  id: z.string().nullable(),
  carrier: z.string().nullable(),
  cost: z.number().nullable(),
});

export const shipmentQuoteBody = z.object({
  fromCustomer: returnShipmentMethod.array(),
  toCustomer: rate.nullable(),
});

const shipmentAddress = z.object({
  toAddressId: z.number(),
  fromAddressId: z.number(),
});

const extendedRate = rate.merge(z.object({ service: z.string() }));

export const adminShippingLabel = shipmentAddress.merge(
  z.object({
    claimId: z.number(),
    returnShipmentType: z.literal(ShipmentMethod.label),
    rate: extendedRate,
    shipmentIdFromPlatform: z.string(),
  }),
);

export const adminPackingSlip = shipmentAddress.merge(
  z.object({
    claimId: z.number(),
    returnShipmentType: z.literal(ShipmentMethod.packingSlip),
  }),
);

export const shipmentAdminCreate = z.discriminatedUnion('returnShipmentType', [
  adminPackingSlip,
  adminShippingLabel,
]);

export const shipmentAdminQuote = shipmentAddress.merge(
  z.object({
    weightInOunces: z.number(),
  }),
);

export const shipmentAdminQuoteResponse = z.object({
  shipmentIdFromPlatform: z.string(),
  rates: z.array(extendedRate),
});
