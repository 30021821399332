import { z } from 'zod';
import { CrewClaimTypeEnum } from '../../enums/crew.js';
import { requestedResolutionMethodUnion } from './requestedResolutionMethod.js';
import { rate, returnShipmentMethod } from './shipmentRate.js';

export const afterReasonSelectionQuery = z.object({
  lineItemId: z.coerce.number(),
  claimType: z.union([
    z.literal(`${CrewClaimTypeEnum.return}`),
    z.literal(`${CrewClaimTypeEnum.warranty}`),
  ]),
  reasonId: z.coerce.number(),
  reasonCategoryCode: z.coerce.string(),
  reasonDetailId: z.coerce.number().optional(),
});

export const automationLineItem = afterReasonSelectionQuery.merge(
  z.object({
    requestedResolutionMethod: requestedResolutionMethodUnion,
    lineItemTotal: z.coerce.number(),
    customFields: z
      .array(
        z.object({
          id: z.coerce.number(),
          value: z.string(),
        }),
      )
      .optional(),
  }),
);

export const automationLineItems = z.array(automationLineItem);

const beforeClaimSubmissionRefundOptions = z.object({
  kind: z.literal('estimateIncentive'),
  lineItems: automationLineItems,
}); // ? to be filled in later

const beforeClaimSubmissionReturn = z.object({
  kind: z.literal('beforeClaimSubmissionReturn'),
  fromCustomer: returnShipmentMethod,
  toCustomer: rate.nullable(),
  lineItems: automationLineItems,
});

const beforeClaimSubmissionWarranty = z.object({
  kind: z.literal('beforeClaimSubmissionWarranty'),
  lineItems: automationLineItems,
});

export const beforeClaimSubmissionBody = z.discriminatedUnion('kind', [
  beforeClaimSubmissionRefundOptions,
  beforeClaimSubmissionReturn,
  beforeClaimSubmissionWarranty,
]);
