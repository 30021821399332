import {
  LabelHTMLAttributes,
  ParamHTMLAttributes,
  PropsWithChildren,
} from 'react';
import { twMerge } from 'tailwind-merge';

function Root({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={twMerge('flex w-full flex-col gap-2', className)}>
      {children}
    </div>
  );
}

function Label({
  children,
  className,
  htmlFor,
  ...rest
}: LabelHTMLAttributes<HTMLLabelElement>) {
  return (
    <label
      {...rest}
      htmlFor={htmlFor}
      className={twMerge(
        'bg-transparent text-sm font-medium text-gray-700',
        className,
      )}
    >
      {children}
    </label>
  );
}

function HelpText({
  children,
  className,
  error,
  ...rest
}: ParamHTMLAttributes<HTMLParagraphElement> & { error?: boolean }) {
  return (
    <p
      {...rest}
      className={twMerge(
        'text-sm',
        error ? 'text-error' : 'text-gray-700',
        className,
      )}
    >
      {children}
    </p>
  );
}

export default { HelpText, Label, Root };
