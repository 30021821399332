import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { getClaimReasons } from '~/utils/claim-line-items';
import {
  createActionResultHook,
  crewAction,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { ActionResult, ClaimReasonCategoryCode } from '~/utils/types';

export const INPUT_NAME = 'reasonId';

const formSchema = zfd.formData({
  [INPUT_NAME]: zfd.numeric(
    z
      .number({ message: 'Please choose a reason.' })
      .int({ message: 'Invalid reason ID.' })
      .positive({ message: 'Invalid reason ID.' }),
  ),
});

export default crewAction(
  withDraftClaim(({ formData, context, claim }) => {
    const { order } = context;
    const result = formSchema.safeParse(formData);

    if (!result.success) {
      return json<ActionResult<typeof INPUT_NAME>>({
        ok: false,
        message: result.error.message,
      });
    }

    const { reasonId } = result.data;

    const reason = getClaimReasons(claim).find(({ id }) => id === reasonId);

    if (!reason) {
      return json<ActionResult<typeof INPUT_NAME>>({
        ok: false,
        message: 'Invalid claim reason.',
      });
    }

    context.setDraftClaim({
      ...claim,
      reason: {
        id: reason.id,
        name: reason.name,
        category: reason.category.code,
      },
    });

    const hasShippingProtection =
      !!order?.wasShippingProtected &&
      reason.category.code === ClaimReasonCategoryCode.shippingIssue;

    return hasShippingProtection ?
        redirect('../shipping-protection', StatusCodes.SEE_OTHER)
      : redirect(`${reasonId}`, StatusCodes.SEE_OTHER);
  }),
);

export const useReasonActionResult =
  createActionResultHook<ActionResult<typeof INPUT_NAME>>();
