import { z } from 'zod';
import api from '~/utils/api';
import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { AfterReasonSelectionEvent, ClaimType } from '~/utils/types';

export type FollowUpData = {
  eventPolicyEnforcement: NonNullable<
    AfterReasonSelectionEvent['eventPolicyEnforcement']
  >;
  orderId: string;
  claimType: string;
  store: string;
};

const paramsSchema = z.object({
  idFromPlatform: z.string(),
  claimType: z.nativeEnum(ClaimType),
  store: z.string(),
});

export default crewLoader(
  withDraftClaim(
    ({
      claim,
      context: {
        settings: { storefrontId },
        order,
      },
      params,
    }) => {
      const { idFromPlatform, claimType, store } = paramsSchema.parse(params);

      if (!order) {
        return redirect(`/${store}/order/${idFromPlatform}/${claimType}`);
      }

      if (!claim.reason) {
        return error(new Error('Missing claim reason.'));
      }

      return api
        .checkAfterReasonSelection({
          params: { storefrontId },
          query: {
            lineItemId: claim.lineItem.id,
            reasonId: claim.reason.id,
            claimType: claim.claimType,
            reasonCategoryCode: claim.reason.category,
            ...(claim.reason.detail && {
              reasonDetailId: claim.reason.detail.id,
            }),
          },
        })
        .then(({ eventPolicyEnforcement }) =>
          eventPolicyEnforcement ?
            json<FollowUpData>({
              eventPolicyEnforcement,
              orderId: order.idFromPlatform,
              claimType,
              store,
            })
            // return a redirect with the event custom fields if it exists
          : redirect('../meta'),
        );
    },
  ),
);

export const useFollowUpData = createLoaderDataHook<FollowUpData>();
