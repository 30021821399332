import {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Field from './field';

type InputPrimitiveProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'placeholder' | 'id'
> & {
  id: string;
  type?: Exclude<HTMLInputTypeAttribute, 'submit' | 'file' | 'button'>;
  validationMessages?: Partial<Record<ValidationConstraint, string>>;
};

const baseStyles =
  'w-full rounded-md border-outline py-2 px-4 text-base duration-100 ease-linear focus:border-primary focus:ring-primary data-[empty]:text-transparent data-[empty]:focus:text-gray-700';
const numberStyles =
  'text-right [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none';
const disabledStyles =
  'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200';
const invalidStyles =
  'data-[invalid]:border-error data-[invalid]:focus:border-error data-[invalid]:focus:ring-error ';

export const InputPrimitive = forwardRef<HTMLInputElement, InputPrimitiveProps>(
  (
    {
      className,
      type = 'text',
      onChange,
      onBlur,
      onInvalid,
      value,
      defaultValue,
      validationMessages = {},
      ...rest
    },
    ref,
  ) => {
    const [isEmpty, setIsEmpty] = useState(!(value || defaultValue));
    const [isValid, setIsValid] = useState(true);
    // Set valid data attribute on input invalid event to style the input trigger by submitting the form
    const handleInvalid: FormEventHandler<HTMLInputElement> = (event) => {
      const input = event.currentTarget;

      setIsValid(input.validity.valid);

      if (!input.validity.valid) {
        const constraint = Object.keys(validationMessages).find(
          (key) => input.validity[key as keyof ValidityState],
        ) as keyof typeof validationMessages;

        const message =
          constraint && validationMessages[constraint] ?
            validationMessages[constraint]
          : input.validationMessage;

        /**
         * * sets the validation message that is also displayed in native popup message
         * ! this will need to be cleared before checking the validity again
         * ! because it will set the validity of `customError` to true
         */
        input.setCustomValidity(message);
      }

      onInvalid?.(event);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
      const input = event.currentTarget;

      if (!isEmpty) {
        setIsValid(input.validity.valid);
      }

      onBlur?.(event);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const input = event.currentTarget;
      /*
       * not really a good, consistent way to check if the input is empty (has no value),
       * especially since not allowing the placeholder
       */
      setIsEmpty(!input.value.trim());

      // !Clear the custom validity message `customError` before checking the validity again
      input.setCustomValidity('');

      // Update the validity state on change if the input is in invalid state
      if (!isValid) {
        setIsValid(input.validity.valid);
      }

      onChange?.(event);
    };

    return (
      <input
        {...rest}
        type={type}
        className={twMerge(
          baseStyles,
          type === 'number' && numberStyles,
          disabledStyles,
          invalidStyles,
          className,
        )}
        value={value}
        defaultValue={defaultValue}
        data-empty={isEmpty ? '' : undefined}
        data-invalid={isValid ? undefined : ''}
        onChange={handleChange}
        onBlur={handleBlur}
        onInvalid={handleInvalid}
        ref={ref}
      />
    );
  },
);

type ValidationConstraint = Exclude<keyof ValidityState, 'valid'>;

export default function Input({
  id,
  label,
  className,
  description,

  ...inputProps
}: {
  label: string;
  description?: ReactNode;
  validationMessages?: Partial<Record<ValidationConstraint, string>>;
} & InputPrimitiveProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // This watches for changes in the input's validity and sets the error message accordingly
  useEffect(() => {
    const target = ref.current;

    const observer = new MutationObserver(() => {
      if (!target) return;

      if (target.validity.valid) {
        setErrorMessage(null);
      } else {
        setErrorMessage(target.validationMessage);
      }
    });
    if (target) {
      observer.observe(target, {
        attributes: true,
        attributeFilter: ['data-invalid'],
      });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Field.Root className="relative">
      <InputPrimitive
        // eslint-disable-next-line react/jsx-props-no-spreading -- the props extend the input primitive props
        {...inputProps}
        id={id}
        className={twMerge(className, 'peer pb-1 pt-5')}
        ref={ref}
        aria-describedby={`${id}-help-text`}
      />
      <Field.Label
        className={twMerge(
          'pointer-events-none absolute left-0 ml-4 p-0 duration-100 ease-linear',
          // when the input has a value
          'translate-y-1 text-xs',
          // when the input is focused and empty
          'peer-data-[empty]:peer-focus:translate-y-1 peer-data-[empty]:peer-focus:text-xs',
          // when the input is empty
          'peer-data-[empty]:translate-y-4 peer-data-[empty]:text-sm peer-data-[empty]:text-gray-500',
        )}
        htmlFor={id}
      >
        {`${label}${inputProps.required ? '' : ' (optional)'}`}
      </Field.Label>
      {(errorMessage || description) && (
        <Field.HelpText id={`${id}-help-text`} error={!!errorMessage}>
          {errorMessage ?? description}
        </Field.HelpText>
      )}
    </Field.Root>
  );
}
